













































import ActionTargets from "@/components/TriggerSetup/components/ActionTargets/ActionTargets.vue";
import ActionChance from "@/components/TriggerSetup/components/ActionChance/ActionChance.vue";
import { ActionSetupView } from "@/components/TriggerSetup/logic/mixins/action-setup-view.mixin";

import { StepUnit } from "piramis-base-components/src/components/PeriodSimplifier/types";
import Icon from "piramis-base-components/src/components/Icon/Icon.vue";

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  'components': {
    ActionTargets,
    ActionChance,
    Icon
  },
  data() {
    return {
      StepUnit
    }
  }
})
export default class MuteActionSetupView extends Mixins<ActionSetupView>(ActionSetupView) {

}
