var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"delete-message-action"},[_c('action-targets',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}),_c('time-unit-input',{attrs:{"setup":{
      'func': _vm.triggerInputSetupFn,
      'args': {
        model: _vm.model,
        'key': 'delay',
        'prefix': 'trigger_action_',
        disabled: _vm.disabled,
        'validation': 'required',
        'units': [_vm.StepUnit.Second, _vm.StepUnit.Minute, _vm.StepUnit.Hour, _vm.StepUnit.Day],
        'max': 172800,
        'min': 0
      }
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }