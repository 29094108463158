var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"trigger-advanced-user-limits-settings"},[_c('a-card',{staticClass:"h-full font-medium text-center relative",class:{'border-2 border-success': _vm.isModified},attrs:{"body-style":{
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}},[_c('help-message',{staticClass:"absolute top-0 hover:text-primary",staticStyle:{"right":"5px"},attrs:{"help-message":_vm.$t('trigger_advanced_user_limits_settings_help_message')}}),_c('div',{staticClass:"hover:text-primary cursor-pointer",on:{"click":_vm.openPopup}},[_vm._v(" "+_vm._s(_vm.$t('trigger_advanced_user_limits_settings'))+" ")])],1),_c('overlay',{staticClass:"trigger-advanced__overlay overflow-scroll p-4",model:{value:(_vm.isPopupOpen),callback:function ($$v) {_vm.isPopupOpen=$$v},expression:"isPopupOpen"}},[_c('centered-column-layout',[_c('div',{staticClass:"overlay__header flex justify-between items-center"},[_c('h2',[_vm._v(" "+_vm._s(_vm.$t('trigger_advanced_user_limits_settings'))+" "),_c('sup',[_c('help-message',{staticClass:"inline",attrs:{"help-message":_vm.$t('trigger_advanced_user_limits_settings_help_message')}})],1)]),_c('a-button',{attrs:{"type":"primary","icon":"close"},on:{"click":function($event){_vm.isPopupOpen = false}}})],1),(_vm.modelCopy)?_c('validation-observer',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var invalid = ref.invalid;
return [_c('number-input',{attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.modelCopy,
              'key': 'limit',
              'prefix': 'trigger_setup_',
              'disabled': _vm.disabled,
              'settingWrapper': {
                customiseValue: 1,
                hasFieldButton: true,
                disabledValue: 0
              }
            }
          }}}),(_vm.modelCopy.limit > 0)?_c('nested-content',[_c('time-unit-input',{staticClass:"mt-0",attrs:{"setup":{
              'func': _vm.newConfigInputSetup,
              'args': {
                'model': _vm.modelCopy,
                'key': 'limitInterval',
                'prefix': 'trigger_setup_',
                'disabled': _vm.disabled,
                'settingWrapper': {
                  customiseValue: _vm.StepUnit.Hour,
                  hasFieldButton: true,
                  disabledValue: 0
                }
              }
            }}}),(_vm.modelCopy.limitInterval > 0)?_c('nested-content',[_c('switch-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
                'func': _vm.newConfigInputSetup,
                'args': {
                  'model': _vm.modelCopy,
                  'key': 'roundLimitInterval',
                  'prefix': 'trigger_setup_',
                  'disabled': _vm.disabled,
                }
              }}})],1):_vm._e(),_c('div',[_c('h2',[_vm._v(" "+_vm._s(_vm.$t('trigger_setup_limit_actions'))+" "),_c('sup',[_c('help-message',{staticClass:"inline",attrs:{"help-message":_vm.$t('trigger_setup_limit_actions_help_message')}})],1)]),_c('actions-groups',{attrs:{"show-from-to":true,"disabled":_vm.disabled,"filtered-actions":_vm.filteredActions,"action-execute-type":_vm.model.actionExecuteType,"has-form":_vm.hasForm},model:{value:(_vm.limitActionsGroups),callback:function ($$v) {_vm.limitActionsGroups=$$v},expression:"limitActionsGroups"}})],1)],1):_vm._e(),_c('trigger-advanced-validate-mixin',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var invalidTriggerField = ref.invalidTriggerField;
return [_c('div',{staticClass:"flex flex-row items-center justify-end mt-base"},[_c('div',{staticClass:"flex flex-col"},[_c('a-button',{staticClass:"btn-success",attrs:{"icon":"check","disabled":invalid || _vm.disabled || invalidTriggerField !== null},on:{"click":_vm.saveChanges}},[_vm._v(" "+_vm._s(_vm.$t('trigger_advanced_button_save'))+" ")]),(invalidTriggerField)?_c('div',{staticClass:"flex flex-row items-center justify-center mt-2"},[_c('a-icon',{staticClass:"mr-2 text-danger",attrs:{"type":"close"}}),_vm._v(" "+_vm._s(_vm.$t(invalidTriggerField))+" ")],1):_vm._e()],1)])]}}],null,true),model:{value:(_vm.limitActionsGroups),callback:function ($$v) {_vm.limitActionsGroups=$$v},expression:"limitActionsGroups"}})]}}],null,false,2309276185)}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }