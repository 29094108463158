var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"trigger-advanced-warn-actions-settings"},[_c('a-card',{staticClass:"h-full hover:text-primary font-medium text-center",class:{'border-2 border-success': _vm.isModified},attrs:{"hoverable":"","body-style":{
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }},nativeOn:{"click":function($event){return _vm.openPopup.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('trigger_advanced_warn_actions_settings'))+" ")]),_c('overlay',{staticClass:"trigger-advanced__overlay overflow-scroll p-4",model:{value:(_vm.isPopupOpen),callback:function ($$v) {_vm.isPopupOpen=$$v},expression:"isPopupOpen"}},[_c('centered-column-layout',[_c('div',{staticClass:"overlay__header flex justify-between items-center"},[_c('h2',[_vm._v(_vm._s(_vm.$t('trigger_advanced_warn_actions_settings')))]),_c('a-button',{attrs:{"type":"primary","icon":"close"},on:{"click":function($event){_vm.isPopupOpen = false}}})],1),(_vm.modelCopy)?_c('validation-observer',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var invalid = ref.invalid;
return [_c('number-input',{attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.modelCopy,
              'key': 'warnLimit',
              'prefix': 'trigger_setup_',
              'disabled': _vm.disabled,
            }
          }}}),_c('time-unit-input',{attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.modelCopy,
              'key': 'warnLimitInterval',
              'prefix': 'trigger_setup_',
              'disabled': _vm.disabled,
            }
          }}}),_c('switch-input',{attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.modelCopy,
              'key': 'roundWarnLimitInterval',
              'prefix': 'trigger_setup_',
              'disabled': _vm.disabled,
            }
          }}}),_c('div',[_c('h2',[_vm._v(_vm._s(_vm.$t('trigger_setup_warn_actions')))]),_c('actions-groups',{attrs:{"show-from-to":true,"disabled":_vm.disabled,"filtered-actions":_vm.filteredActions,"action-execute-type":_vm.model.actionExecuteType,"has-form":_vm.hasForm},model:{value:(_vm.warnActionsGroups),callback:function ($$v) {_vm.warnActionsGroups=$$v},expression:"warnActionsGroups"}})],1),_c('trigger-advanced-validate-mixin',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var invalidTriggerField = ref.invalidTriggerField;
return [_c('div',{staticClass:"flex flex-row items-center justify-end mt-base"},[_c('div',{staticClass:"flex flex-col"},[_c('a-button',{staticClass:"btn-success",attrs:{"icon":"check","disabled":invalid || _vm.disabled || invalidTriggerField !== null},on:{"click":_vm.saveChanges}},[_vm._v(" "+_vm._s(_vm.$t('trigger_advanced_button_save'))+" ")]),(invalidTriggerField)?_c('div',{staticClass:"flex flex-row items-center justify-center mt-2"},[_c('a-icon',{staticClass:"text-danger",attrs:{"type":"close"}}),_vm._v(" "+_vm._s(_vm.$t(invalidTriggerField))+" ")],1):_vm._e()],1)])]}}],null,true),model:{value:(_vm.warnActionsGroups),callback:function ($$v) {_vm.warnActionsGroups=$$v},expression:"warnActionsGroups"}})]}}],null,false,4255438705)}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }