import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import BanActionSetupView from "@/components/TriggerSetup/actions/BanAction/BanActionSetupView.vue";
import { ActionsType } from "@/components/TriggerSetup/logic/types/types";
import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";

const action: EntityData = {
  'setupView': BanActionSetupView,
  'structure': {
    'type': ActionsType.BanAction,
    'target': "Caller",
    'time'  : 0, // Секунд
    'chance': 100,
    'targetSource': ''
  },
  ...entityDataTitleKeyFields(ActionsType.BanAction, 'trigger_action_'),
}

export default action
