





























import { ActionSetupView } from "@/components/TriggerSetup/logic/mixins/action-setup-view.mixin";
import ActionTargets from "@/components/TriggerSetup/components/ActionTargets/ActionTargets.vue";
import ActionChance from "@/components/TriggerSetup/components/ActionChance/ActionChance.vue";

import { Component, Mixins } from 'vue-property-decorator'
import { SelectOption } from "piramis-base-components/src/logic/types";

@Component({
  'components': {
    ActionTargets,
    ActionChance,
  }
})
export default class PinMessageActionSetupView extends Mixins<ActionSetupView>(ActionSetupView) {
  replyOptions: Array<SelectOption> = [
    {
      label: this.$t('pin_unpin_message_action_reply_false_option').toString(),
      value: false,
    },
    {
      label: this.$t('pin_unpin_message_action_reply_true_option').toString(),
      value: true
    }
  ]
}
