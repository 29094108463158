import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { baseCondition } from "@/components/TriggerSetup/logic/structures/base-condition.structure";
import { ConditionsType, TriggerCondition } from "@/components/TriggerSetup/logic/types/types";
import WorkTimeSetupView from "@/components/TriggerSetup/conditions/WorkTime/WorkTimeSetupView.vue";
import { snakeCase } from "lodash";
import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";

const condition: EntityData = {
  'setupView': WorkTimeSetupView,
  'structure': {
    'type': ConditionsType.WorkTime,
    ...baseCondition,
  } as TriggerCondition,
  ...entityDataTitleKeyFields(ConditionsType.WorkTime, 'trigger_condition_'),
}

export default condition
