import { ConditionZone } from "@/components/TriggerSetup/logic/types/types";
import { SelectOptionData } from "piramis-base-components/src/components/Pi/types";
import { snakeCase } from "lodash";
import i18n from "@/i18n/i18n";

export function getConditionZoneIcon(zone: ConditionZone): SelectOptionData['icon'] {
  switch (zone) {
    case ConditionZone.Common:
      return {
        name: 'bolt'
      }
    case ConditionZone.Message:
      return {
        name: 'message'
      }
    case ConditionZone.ReplyMessage:
      return {
        name: 'reply'
      }
    case ConditionZone.RequestActor:
      return {
        name: 'person_pin_circle'
      }
    case ConditionZone.RequestMessage:
      return {
        name: 'contact_mail',
      }
    case ConditionZone.ReactionActor:
      return {
        name: 'add_reaction'
      }
    case ConditionZone.ReactionMessage:
      return {
        name: 'emoji_emotions'
      }
  }
}

export function getConditionZoneTitle(cz: ConditionZone) {
  return i18n.t(`trigger_condition_zone_${ snakeCase(cz) }`).toString()
}

export function getConditionZoneMiniHelpMessage(cz: ConditionZone) {
  return i18n.t(`trigger_condition_zone_${ snakeCase(cz) }_mini_help_message`).toString()
}

