import { ConditionsWrapperType } from "../../../logic/types/types";
import MessageTextMaxLen from "./conditions/MessageTextMaxLen";
import MessageTextMinLen from "./conditions/MessageTextMinLen";
import MessageTextLenConditionsWrapper from "./MessageTextLenConditionsWrapper.vue";
import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";

const condition: EntityData = {
  setupView: MessageTextLenConditionsWrapper,
  ...entityDataTitleKeyFields(ConditionsWrapperType.MessageTextLenConditionsWrapper, 'trigger_condition_'),
  structure: MessageTextMaxLen.structure,
  extraProps: {
    conditions: [
      MessageTextMaxLen,
      MessageTextMinLen,
    ],
  }
}

export default condition
