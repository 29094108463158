import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { ConditionsWrapperType } from "@/components/TriggerSetup/logic/types/types";
import ReputationConditionsWrapper from "./ReputationConditionsWrapper.vue";

import store from "@/store/store";
import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";
import { ADVANCED_TAG } from "@/includes/constants";
import ReputationUpper from "./conditions/ReputationUpper";
import ReputationLower from "./conditions/ReputationLower";
import ReputationEquals from "./conditions/ReputationEquals";

const condition: EntityData = {
  'setupView': ReputationConditionsWrapper,
  'structure': ReputationEquals.structure,
  extraProps: {
    conditions: [
      ReputationEquals,
      ReputationUpper,
      ReputationLower,
    ],
  },
  ...entityDataTitleKeyFields(ConditionsWrapperType.ReputationConditionsWrapper, 'trigger_condition_'),
  limited: () => {
    if (store.getters.isChatSet && store.getters.isChatLicenseExists && store.getters.isChatAtLeastAdvanced) {
      return null
    } else {
      return ADVANCED_TAG
    }
  }
}

export default condition
