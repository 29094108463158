import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { ActionsType } from "@/components/TriggerSetup/logic/types/types";
import { entityDataTitleKeyFields } from "../../logic/helpers";
import CompleteCaptchaQuestActionSetupView from "./CompleteCaptchaQuestActionSetupView.vue";
import store from "@/store/store";
import { ULTIMATE_TAG } from "@/includes/constants";

const action: EntityData = {
  'setupView': CompleteCaptchaQuestActionSetupView,
  'structure': {
    'type': ActionsType.CompleteCaptchaQuestAction,
    'target'      : "Caller",
    'chance'      : 100,
  },
  ...entityDataTitleKeyFields(ActionsType.CompleteCaptchaQuestAction, 'trigger_action_'),
  limited: () => store.getters.getHaveLicense && store.getters.isChatLicenseExists ? null : ULTIMATE_TAG
}

export default action
