

























import { ConditionSetupView } from '../../logic/mixins/condition-setup-view.mixin'
import ConditionInvertSettings
  from "../../components/ConditionInvertSettings/ConditionInvertSettings.vue";

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  components: { ConditionInvertSettings }
})
export default class CheckPremiumConditionsWrapper extends Mixins<ConditionSetupView>(ConditionSetupView){
}
