import { ConditionsWrapperType } from "../../../logic/types/types";
import MessageTextEndWith from "./conditions/MessageTextEndWith";
import MessageTextFullMatch from "./conditions/MessageTextFullMatch";
import MessageTextStartWith from "./conditions/MessageTextStartWith";

import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";
import MessageTextSubstringMatch from "../MessageTextSubstringMatch";

const condition: EntityData = {
  ...entityDataTitleKeyFields(ConditionsWrapperType.MessageTextConditionsWrapper, 'trigger_condition_'),
  structure: MessageTextFullMatch.structure,
  extraProps: {
    conditions: [
      MessageTextFullMatch,
      MessageTextEndWith,
      MessageTextStartWith,
      MessageTextSubstringMatch
    ],
  },
  setupView: () => import('./MessageTextConditionsWrapper.vue')
}

export default condition
