import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { snakeCase } from "lodash";

export function entityDataTitleKeyFields(key: string, prefix = ''): Pick<EntityData, 'key' | 'titleKey' | 'helpMessageKey'> {
  const snakeCaseKey = snakeCase(key)

  return {
    key,
    titleKey: prefix + snakeCaseKey,
    helpMessageKey: prefix + snakeCaseKey + "_help_message"
  }
}
