

















import { ConditionSetupView } from "@/components/TriggerSetup/logic/mixins/condition-setup-view.mixin";
import ConditionFiltersSettings
  from "@/components/TriggerSetup/components/ConditionFiltersSettings/ConditionFiltersSettings.vue";
import ConditionUsersSettings
  from "@/components/TriggerSetup/components/ConditionUsersSettings/ConditionUsersSettings.vue";
import ConditionInvertSettings
  from "@/components/TriggerSetup/components/ConditionInvertSettings/ConditionInvertSettings.vue";

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  'components': {
    ConditionFiltersSettings,
    ConditionUsersSettings,
    ConditionInvertSettings
  }
})
export default class AdvancedConditionSettings extends Mixins<ConditionSetupView>(ConditionSetupView) {

}
