
























import { ConditionSetupView } from "@/components/TriggerSetup/logic/mixins/condition-setup-view.mixin";
import ConditionInvertSettings
  from "@/components/TriggerSetup/components/ConditionInvertSettings/ConditionInvertSettings.vue";

import { SelectOption } from "piramis-base-components/src/logic/types";

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  'components': {
    ConditionInvertSettings
  }
})
export default class HasAchievementSetupView extends Mixins(ConditionSetupView) {
  get stringifiedValueOptions() {
    return this.$store.getters.achievementOptions.map((o: SelectOption) => {
      return {
        ...o,
        value: o.value.toString()
      }
    })
  }

  get achievementsValues():Array<number> {
    return this.conditionData.value
  }

  set achievementsValues(value:Array<number>) {
    if (value.length <= 10) {
      this.conditionData.value = value
    }
  }
}
