


























import { ActionSetupView } from "@/components/TriggerSetup/logic/mixins/action-setup-view.mixin";
import ActionText from "@/components/TriggerSetup/components/ActionText/ActionText.vue";
import ActionFormButtonAction
  from "@/components/TriggerSetup/components/ActionFormButtonAction/ActionFormButtonAction.vue";
import Placeholders from "@/mixins/placeholders/placeholders";
import TriggerActionBindTarget from "@/components/TriggerSetup/components/TriggerActionBindTarget.vue";

import { Component, Mixins, Prop } from 'vue-property-decorator'

@Component({
  'components': {
    TriggerActionBindTarget,
    ActionText,
    ActionFormButtonAction,
  }
})
export default class SendMessageToTargetActionSetup extends Mixins<ActionSetupView, Placeholders>(ActionSetupView, Placeholders) {
  @Prop({ 'type': Boolean, 'required': false, 'default': false }) hasForm!:boolean
}
