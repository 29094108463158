













import { ActionSetupView } from "@/components/TriggerSetup/logic/mixins/action-setup-view.mixin";
import ActionTargets from "@/components/TriggerSetup/components/ActionTargets/ActionTargets.vue";
import ActionChance from "@/components/TriggerSetup/components/ActionChance/ActionChance.vue";

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  'components': {
    ActionTargets,
    ActionChance,
  }
})
export default class UnmuteActionSetupView extends Mixins<ActionSetupView>(ActionSetupView) {

}
