





















import { ActionSetupView } from "@/components/TriggerSetup/logic/mixins/action-setup-view.mixin";

import WarnActionSetupView from '@/components/TriggerSetup/actions/WarnAction'
import SilentWarnActionSetupView from '@/components/TriggerSetup/actions/SilentWarnAction'

import { Component, Mixins } from 'vue-property-decorator'

@Component
export default class WarnActionsWrapper extends Mixins<ActionSetupView>(ActionSetupView) {
  get viewBySendWarnMessage():any {
    if (this.model.sendWarnMessage) {
      return WarnActionSetupView
    } else {
      return SilentWarnActionSetupView
    }
  }

  onChange():void {
    this.model.currentAction = this.viewBySendWarnMessage.structure
  }
}
