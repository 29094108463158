var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.localModel)?_c('div',{staticClass:"timezone-select"},[_c('a-modal',{staticClass:"modal-overflow-visible",attrs:{"after-close":_vm.setLocalModel,"ok-text":_vm.$t('save_button')},on:{"ok":_vm.setTimezone},model:{value:(_vm.isTimezoneModalOpen),callback:function ($$v) {_vm.isTimezoneModalOpen=$$v},expression:"isTimezoneModalOpen"}},[_c('select-input',{staticClass:"mb-0 w-full",attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.localModel,
          'key': 'timezone',
          'options': _vm.timezoneList,
          'clearable': false,
          'hasAnchor': true,
        }
      }}})],1),_c('div',[_c('a-icon',{attrs:{"type":"global"}}),_c('span',{staticClass:"text-sm md:text-base"},[_vm._v(" "+_vm._s(_vm.$t('field_timezone_title'))+" "),_c('a',{staticClass:"font-semibold inline underline align-baseline select-none",on:{"click":function($event){$event.preventDefault();_vm.isTimezoneModalOpen = true}}},[_vm._v(" "+_vm._s(_vm.timezoneLabel)+" "),_c('a-icon',{staticClass:"text-sm",attrs:{"type":"caret-down"}})],1)])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }