





















import { ConditionSetupView } from "@/components/TriggerSetup/logic/mixins/condition-setup-view.mixin";
import AdvancedConditionSettings
  from "@/components/TriggerSetup/components/AdvancedConditionSettings/AdvancedConditionSettings.vue";
import ConditionInvertSettings
  from '@/components/TriggerSetup/components/ConditionInvertSettings/ConditionInvertSettings.vue'

import { Component, Mixins, Prop } from 'vue-property-decorator'

@Component({
  'components': {
    AdvancedConditionSettings,
    ConditionInvertSettings
  }
})
export default class CheckBindedChannelSetupView extends Mixins<ConditionSetupView>(ConditionSetupView) {
  @Prop({ type: Boolean }) disabled!: boolean

  get channels() {
    return this.conditionData.value
  }

  set channels(value: Array<string>) {
    this.conditionData.value = value.slice(0, 1)
  }
}
