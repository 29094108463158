var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"change-points-action"},[_c('action-interval',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}),_c('time-unit-input',{attrs:{"setup":{
      'func': _vm.triggerInputSetupFn,
      'args': {
        'model': _vm.model,
        'key': 'lifetime',
        'prefix': 'action_interval_',
        'settingWrapper': {
          value: 0,
          hasFieldButton: true,
          customiseValue: 10,
        }
      }
    }}}),(_vm.model.lifetime > 0)?_c('nested-content',[_c('switch-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
        'func': _vm.triggerInputSetupFn,
        'args': {
          'model': _vm.model,
          'key': 'positiveLifetime',
        }
      }}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }