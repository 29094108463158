import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { newBaseCondition } from "@/components/TriggerSetup/logic/structures/base-condition.structure";
import NumberConditionSetupView
  from "@/components/TriggerSetup/components/NumberConditionSetupView/NumberConditionSetupView.vue";
import {
  ConditionsType,
  ConditionZone,
  TriggerCondition
} from "@/components/TriggerSetup/logic/types/types";
import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";

const condition: EntityData = {
  'setupView': NumberConditionSetupView,
  'structure': {
    'condition_type': ConditionsType.UserNameMaxLen,
    ...newBaseCondition,
    ...{ 'value': [ 0 ] }
  } as TriggerCondition,
  ...entityDataTitleKeyFields(ConditionsType.UserNameMaxLen, 'trigger_condition_'),
  extraProps: {
    possibleConditionZone: [ ConditionZone.Message, ConditionZone.ReplyMessage, ConditionZone.RequestActor, ConditionZone.ReactionActor,
      ConditionZone.ReactionMessage ]
  }
}

export default condition
