






































import { ConditionSetupView } from "@/components/TriggerSetup/logic/mixins/condition-setup-view.mixin";
import ConditionInvertSettings
  from "@/components/TriggerSetup/components/ConditionInvertSettings/ConditionInvertSettings.vue";

import { SelectOption } from "piramis-base-components/src/logic/types";

import { Component, Mixins } from 'vue-property-decorator'
import SelectInput from "piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue";
import SelectOptionsMixin from "@/mixins/SelectOptionsMixin";

@Component({
  'components': {
    ConditionInvertSettings,
    SelectInput
  }
})
export default class HasSimilarMessageSetupView extends Mixins(ConditionSetupView, SelectOptionsMixin) {
  get percent(): number {
    return this.conditionData.value[0]
  }

  set percent(value:number) {
    this.conditionData.value.splice(0, 1, value)
  }

  get messages() {
    return this.conditionData.value.slice(1)
  }

  set messages(value: Array<string>) {
    if (value.length <= 9) {
      this.conditionData.value.splice(1, 9, ...value)
    }
  }

  get percentOptions(): Array<SelectOption> {
    return this.aiDetectPercentOptions.map(o => ({
      ...o,
      value: o.value.toString()
    }))
  }
}
