import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import SetReputationActionSetupView
  from "@/components/TriggerSetup/actions/SetReputationAction/SetReputationActionSetupView.vue";
import { ActionsType } from "@/components/TriggerSetup/logic/types/types";
import { entityDataTitleKeyFields } from "../../logic/helpers";

const action: EntityData = {
  'setupView': SetReputationActionSetupView,
  'structure': {
    'type': ActionsType.SetReputationAction,
    'target': "Caller",
    'value' : 0, // can be positive and negative
    // from  : 0, // can be positive and negative
    // to    : 0,
    'chance': 100,
  },
  ...entityDataTitleKeyFields(ActionsType.SetReputationAction, 'trigger_action_'),
}

export default action
