













































import ConditionInvertSettings
  from "@/components/TriggerSetup/components/ConditionInvertSettings/ConditionInvertSettings.vue";
import { ConditionSetupView } from "@/components/TriggerSetup/logic/mixins/condition-setup-view.mixin";

import { Component, Mixins } from 'vue-property-decorator'
import TimezoneSelect from "@/components/TimezoneSelect.vue";

@Component({
  'components': {
    TimezoneSelect,
    ConditionInvertSettings
  }
})
export default class WorkTimeSetupView extends Mixins<ConditionSetupView>(ConditionSetupView) {
  get from(): string {
    return this.conditionData.value[0] || ''
  }

  set from(value: string) {
    this.$set(this.conditionData.value, '0', value)
  }

  get to(): string {
    return this.conditionData.value[1] || ''
  }

  set to(value: string) {
    this.$set(this.conditionData.value, '1', value)
  }
}
