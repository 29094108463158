import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import ChangeTriggerActiveStateActionSetupView
  from "@/components/TriggerSetup/actions/ChangeTriggerActiveStateAction/ChangeTriggerActiveStateActionSetupView.vue";
import { ActionsType } from "@/components/TriggerSetup/logic/types/types";
import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";

const action: EntityData = {
  'setupView': ChangeTriggerActiveStateActionSetupView,
  'structure': {
    'type': ActionsType.ChangeTriggerActiveStateAction,
    'triggerKey': null,
    'state'     : false,
    'chance'    : 100,
  },
  ...entityDataTitleKeyFields(ActionsType.ChangeTriggerActiveStateAction, 'trigger_action_'),
}

export default action
