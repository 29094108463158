import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { ConditionsWrapperType } from "@/components/TriggerSetup/logic/types/types";

import FirstUserMessageTimeConditionsWrapper from "./FirstUserMessageTimeConditionsWrapper.vue";
import FirstUserMessageTimeMoreThat from "./conditions/FirstUserMessageTimeMoreThat";
import FirstUserMessageTimeLowerThat from "./conditions/FirstUserMessageTimeLowerThat";
import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";

const condition: EntityData = {
  'setupView': FirstUserMessageTimeConditionsWrapper,
  ...entityDataTitleKeyFields(ConditionsWrapperType.FirstUserMessageTimeConditionsWrapper, 'trigger_condition_'),
  structure: FirstUserMessageTimeMoreThat.structure,
  extraProps: {
    conditions: [
      FirstUserMessageTimeMoreThat,
      FirstUserMessageTimeLowerThat,
    ],
  }
}

export default condition
