import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import WarnActionsWrapper from "./WarnActionsWrapper.vue";
import WarnActionSetupView from "../WarnAction";
import { ActionsWrapperType } from "@/components/TriggerSetup/logic/types/types";

import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";

const action: EntityData = {
  'setupView': WarnActionsWrapper,
  'structure': {
    'type': ActionsWrapperType.WarnActionsWrapper,
    'sendWarnMessage': '',
    'currentAction': WarnActionSetupView.structure
  },
  ...entityDataTitleKeyFields(ActionsWrapperType.WarnActionsWrapper, 'trigger_action_'),
}

export default action
