import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { ConditionsWrapperType } from "@/components/TriggerSetup/logic/types/types";
import LastUserEnterTimeMoreThat from "./conditions/LastUserEnterTimeMoreThat";
import LastUserEnterTimeConditionsWrapper from "./LastUserEnterTimeConditionsWrapper.vue";

import LastUserEnterTimeLowerThat from "./conditions/LastUserEnterTimeLowerThat";
import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";

const condition: EntityData = {
  'setupView': LastUserEnterTimeConditionsWrapper,
  ...entityDataTitleKeyFields(ConditionsWrapperType.LastUserEnterTimeConditionsWrapper, 'trigger_condition_'),
  structure: LastUserEnterTimeLowerThat.structure,
  extraProps: {
    conditions: [
      LastUserEnterTimeLowerThat,
      LastUserEnterTimeMoreThat,
    ],
  }
}

export default condition
