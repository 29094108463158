












































































































import { ConditionSetupView } from "@/components/TriggerSetup/logic/mixins/condition-setup-view.mixin";
import ConditionInvertSettings
  from '@/components/TriggerSetup/components/ConditionInvertSettings/ConditionInvertSettings.vue'
import { CheckRegexpEqualityEnum } from "@/components/TriggerSetup/conditions/new/CheckRegexpPlaceholder/types";
import '@/includes/validators/placeholder_validator'

import { IterableItemFactory, IterableListItem } from "piramis-base-components/src/shared/utils/IterableItemFactory";
import ConfigField from "piramis-base-components/src/components/ConfigField/ConfigField.vue";

import { Component, Mixins } from 'vue-property-decorator'
import { ValidationObserver } from "vee-validate";
import { cloneDeep } from "lodash";
import { warningNotification } from "@/includes/NotificationService";

const iterableItemFactory = new IterableItemFactory()

type ConditionPattern = {
  pattern: string
  placeholder: string
}

@Component({
  'components': {
    ConditionInvertSettings,
    ValidationObserver,
    ConfigField
  }
})
export default class CheckRegexpPlaceholderSetupView extends Mixins<ConditionSetupView>(ConditionSetupView) {

  frontendConditions: Array<IterableListItem<ConditionPattern>> = []

  isAddPatternModalOpen = false

  pattern = this.basePatternModel()

  get contextType() {
    return this.conditionData.value[0] === CheckRegexpEqualityEnum.all
  }

  set contextType(value: boolean) {
    const type = value ? CheckRegexpEqualityEnum.all : CheckRegexpEqualityEnum.any

    this.conditionData.value.splice(0, 1, type)
  }

  editClick(pattern: IterableListItem<ConditionPattern>) {
    this.pattern = cloneDeep(pattern)
    this.isAddPatternModalOpen = true
  }

  removeClick(guid: IterableListItem<ConditionPattern>['guid']) {
    this.frontendConditions = this.frontendConditions.filter(c => c.guid !== guid)

    warningNotification(this.$t('notification_save_trigger_settings').toString())
  }

  basePatternModel(): IterableListItem<ConditionPattern> {
    return iterableItemFactory.create({
      pattern: '',
      placeholder: ''
    })
  }

  okClick() {
    this.setPattern(this.pattern)
    this.setRawCondition(this.frontendConditions)

    warningNotification(this.$t('notification_save_trigger_settings').toString())

    this.isAddPatternModalOpen = false
  }

  setPattern(pattern: IterableListItem<ConditionPattern>) {
    const { guid } = pattern
    const patternIdx = this.frontendConditions.findIndex(p => p.guid === guid)

    if (patternIdx !== -1) {
      this.frontendConditions.splice(patternIdx, 1, pattern)
    } else {
      this.frontendConditions.push(this.pattern)
    }
  }

  setRawCondition(items: Array<IterableListItem<ConditionPattern>>) {
    const raw = items.map(i => [ i.value.pattern, i.value.placeholder ])
    const rawFlat = raw.flat()

    this.conditionData.value.splice(1, this.conditionData.value.length, ...rawFlat)
  }

  setFrontendConditions(rawPatterns: Array<string>) {
    rawPatterns.forEach((_, index, array) => {
      if (index % 2 === 0) {
        this.frontendConditions.push(iterableItemFactory.create({
          pattern: array[index],
          placeholder: array[index+1]
        }))
      }
    })
  }

  created() {
    this.setFrontendConditions(this.conditionData.value.slice(1))
  }
}
