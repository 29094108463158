import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { newBaseCondition } from "@/components/TriggerSetup/logic/structures/base-condition.structure";
import CallerConditionSetupView from "@/components/TriggerSetup/conditions/CallerType/CallerConditionSetupView.vue";
import {
  ConditionsType,
  ConditionsWrapperType,
  ConditionZone,
  TriggerCondition
} from "@/components/TriggerSetup/logic/types/types";
import { snakeCase } from "lodash";
import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";

const condition: EntityData = {
  'setupView': CallerConditionSetupView,
  'structure': {
    'condition_type': ConditionsType.CallerType,
    ...newBaseCondition,
  } as TriggerCondition,
  ...entityDataTitleKeyFields(ConditionsType.CallerType, 'trigger_condition_'),
  extraProps: {
    possibleConditionZone: [ ConditionZone.Message, ConditionZone.ReplyMessage, ConditionZone.RequestActor, ConditionZone.ReactionActor, ConditionZone.ReactionMessage ]
  }
}

export default condition
