var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ban-action-setup-view"},[_c('action-targets',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}),_c('time-unit-input',{attrs:{"setup":{
      'func': _vm.triggerInputSetupFn,
      'args': {
        model: _vm.model,
        'key': 'time',
        'prefix': 'trigger_action_',
        disabled: _vm.disabled,
        'validation': 'required',
        'settingWrapper': {
          customiseValue: _vm.StepUnit.Day,
          hasFieldButton: true,
          disabledValue: 0
        }
      }
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }