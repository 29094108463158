

























import { Component, Mixins } from 'vue-property-decorator'

import ConditionInvertSettings
  from "@/components/TriggerSetup/components/ConditionInvertSettings/ConditionInvertSettings.vue";
import { ConditionSetupView } from "@/components/TriggerSetup/logic/mixins/condition-setup-view.mixin";

@Component({
  components: { ConditionInvertSettings }
})
export default class CheckPremiumConditionsWrapper extends Mixins<ConditionSetupView>(ConditionSetupView){
}
