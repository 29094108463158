





















import { ConditionSetupView } from '../../../logic/mixins/condition-setup-view.mixin'
import ConditionWrapperMixin from "@/components/TriggerSetup/conditions/new/ConditionWrapperMixin";

import { Component, Mixins } from 'vue-property-decorator'

@Component
export default class DaysWithMessagesConditionsWrapper extends Mixins(ConditionSetupView, ConditionWrapperMixin) {
}
