










import ActionTargets from "@/components/TriggerSetup/components/ActionTargets/ActionTargets.vue";
import { ActionSetupView } from "@/components/TriggerSetup/logic/mixins/action-setup-view.mixin";

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  'components': {
    ActionTargets,
  },
})
export default class CompleteCaptchaQuestActionSetupView extends Mixins<ActionSetupView>(ActionSetupView) {

}
