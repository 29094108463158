import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { baseCondition } from "@/components/TriggerSetup/logic/structures/base-condition.structure";
import { ConditionsType, TriggerCondition } from "@/components/TriggerSetup/logic/types/types";
import CheckBoostSetupView from "../CheckBoost/CheckBoostSetupView.vue";
import { CheckBoostTargetEnum } from "@/components/TriggerSetup/conditions/CheckBoostWrapper/types";

import { snakeCase } from "lodash";
import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";

const condition: EntityData = {
  'setupView': CheckBoostSetupView,
  'structure': {
    'type': ConditionsType.CheckReplyBoost,
    ...baseCondition,
    value: [ CheckBoostTargetEnum.Group ]
  } as TriggerCondition,
  ...entityDataTitleKeyFields(ConditionsType.CheckReplyBoost, 'trigger_condition_'),
}

export default condition
