import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";

import DaysWithMessagesConditionsWrapper from "./DaysWithMessagesConditionsWrapper.vue";
import { ConditionsWrapperType } from "@/components/TriggerSetup/logic/types/types";
import store from "@/store/store";
import { ADVANCED_TAG } from "@/includes/constants";
import DaysWithMessagesLower from "./conditions/DaysWithMessagesLower";
import DaysWithMessagesUpper from "./conditions/DaysWithMessagesUpper";
import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";

const condition: EntityData = {
  'setupView': DaysWithMessagesConditionsWrapper,
  ...entityDataTitleKeyFields(ConditionsWrapperType.DaysWithMessagesConditionsWrapper, 'trigger_condition_'),
  structure: DaysWithMessagesLower.structure,
  extraProps: {
    conditions: [
      DaysWithMessagesLower,
      DaysWithMessagesUpper,
    ],
  },
  limited: () => {
    if (store.getters.isChatSet && store.getters.isChatLicenseExists && store.getters.isChatAtLeastAdvanced) {
      return null
    } else {
      return ADVANCED_TAG
    }
  }
}

export default condition
