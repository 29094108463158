import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import MuteActionSetupView from "@/components/TriggerSetup/actions/MuteAction/MuteActionSetupView.vue";
import { ActionsType } from "@/components/TriggerSetup/logic/types/types";
import { entityDataTitleKeyFields } from "../../logic/helpers";

const action: EntityData = {
  'setupView': MuteActionSetupView,
  'structure': {
    'type': ActionsType.MuteAction,
    'target'      : "Caller",
    'restrictType': null,
    'time'        : 0, // Секунд
    'chance'      : 100,
    'targetSource': ''
  },
  ...entityDataTitleKeyFields(ActionsType.MuteAction, 'trigger_action_'),
}

export default action
