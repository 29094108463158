import { TriggerHelper, TriggerInputsSetups } from "@/components/TriggerSetup/logic/mixins/TriggerSetupMixin";
import { CheckLicenseKey } from "@/components/TriggerSetup/logic/di";
import SelectOptionsMixin from "@/mixins/SelectOptionsMixin";
import { NewTriggerCondition, OldTriggerCondition } from "@/components/TriggerSetup/logic/types/types";

import { UseFields } from 'piramis-base-components/src/components/Pi';
import { SelectOptionData } from "piramis-base-components/src/components/Pi/types";

import { Component, Inject, Mixins, Prop, VModel } from 'vue-property-decorator'

@Component
export class ConditionSetupView extends Mixins(UseFields, TriggerInputsSetups, TriggerHelper, SelectOptionsMixin) {
  @VModel({ 'type': Object }) conditionData!: OldTriggerCondition | NewTriggerCondition

  @Prop({ 'type': Boolean, 'default': false }) readonly disabled!: boolean

  @Inject(CheckLicenseKey) checkLicense!: boolean

  filters(): Promise<Array<SelectOptionData>> {
    return Promise.resolve(this.conditionFilters)
  }

  caller(): Promise<Array<SelectOptionData>> {
    return Promise.resolve(this.conditionCaller)
  }

  messageType(): Promise<Array<SelectOptionData>> {
    return Promise.resolve(this.conditionMessageType)
  }

  conditionCallers(): Promise<Array<SelectOptionData>> {
    return Promise.resolve(this.conditionCaller)
  }
}
