var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"change-trigger-active-state-action"},[_c('select-input',{attrs:{"setup":{
      'func': _vm.triggerInputSetupFn,
      'args': {
        model: _vm.model,
        disabled: _vm.disabled,
        'key': 'triggerKey',
        'prefix': 'trigger_action_',
        'options': _vm.resolveTriggersOptions,
        'validation': 'required',
        'clearable': false,
      }
    }}}),_c('switch-input',{attrs:{"setup":{
      'func': _vm.triggerInputSetupFn,
      'args': {
        model: _vm.model,
        disabled: _vm.disabled,
        'key': 'state',
        'prefix': 'trigger_action_',
        'validation': 'required',
      }
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }