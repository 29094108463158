import { ActionsType, ActionsWrapperType } from "@/components/TriggerSetup/logic/types/types";

import { ItemIcon } from "piramis-base-components/src/components/Pi/components/DefaultList/logic/types";

export const ActionIcons: { [key: string]: ItemIcon } = {
  AddMessageReactionAction: {
    type: "material-icons",
    icon: 'emoji_emotions',
    color: 'gray',
    size: "30px"
  },
  UnPinMessageAction: {
    type: "svg",
    icon: `
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M20 14.274V15h-7v7l-.5 1-.5-1v-6.172L13.828 14h5.163c-.051-.65-.332-.856-1.333-1.515L16.93 12h-1.1l1.16-1.161.927.618c1.302.868 2.084 1.252 2.084 2.817zm2.4-10.966L3.307 22.399l-.707-.707L9.293 15H5v-.726c0-1.565.782-1.95 2.084-2.817l1.147-.765L10 4l-1.522-.43A2.029 2.029 0 0 1 7 1.619V1h11v.618a2.029 2.029 0 0 1-1.478 1.953L15 4l1.107 4.186L21.692 2.6zM10.137 3h4.724l1.388-.392A1.033 1.033 0 0 0 16.926 2H8.074a1.033 1.033 0 0 0 .676.608zm-.954 8h4.109l1.995-1.995L13.966 4h-2.931zm1.109 3l2-2H8.07l-.73.485c-1 .659-1.28.866-1.332 1.515z"/><path fill="none" d="M0 0h24v24H0z"/></svg>`,
  },
  PinMessageAction: {
    type: "material-icons",
    icon: 'push_pin',
    color: 'gray',
    size: "30px"
  },
  BanAction: {
    type: 'svg',
    icon: `
    <svg width="24" height="24" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="rgba(var(--a-danger), 1)">
        <path d="M8 0C6.93913 0 5.92172 0.421427 5.17157 1.17157C4.42143 1.92172 4 2.93913 4 4C4 5.06087 4.42143 6.07828 5.17157 6.82843C5.92172 7.57857 6.93913 8 8 8C9.06087 8 10.0783 7.57857 10.8284 6.82843C11.5786 6.07828 12 5.06087 12 4C12 2.93913 11.5786 1.92172 10.8284 1.17157C10.0783 0.421427 9.06087 0 8 0ZM15.5 9C13 9 11 11 11 13.5C11 16 13 18 15.5 18C18 18 20 16 20 13.5C20 11 18 9 15.5 9ZM8 10C3.58 10 0 11.79 0 14V16H9.5C9.16993 15.2078 9 14.3582 9 13.5C9.00428 12.3143 9.3328 11.1524 9.95 10.14C9.32 10.06 8.68 10 8 10ZM15.5 10.5C17.16 10.5 18.5 11.84 18.5 13.5C18.5 14.06 18.35 14.58 18.08 15L14 10.92C14.42 10.65 14.94 10.5 15.5 10.5ZM12.92 12L17 16.08C16.58 16.35 16.06 16.5 15.5 16.5C13.84 16.5 12.5 15.16 12.5 13.5C12.5 12.94 12.65 12.42 12.92 12Z" />
    </svg>`
  },
  ChangePointsAction: {
    type: 'svg',
    icon: `<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="rgba(var(--a-dark), 1)">
        <path d="M1.38 17.9C0.78 17.71 0.3 17.23 0.0999999 16.63L16.63 0.0999999C17.23 0.3 17.71 0.78 17.9 1.38L1.38 17.9ZM17 13V15H10V13H17ZM0 3H3V0H5V3H8V5H5V8H3V5H0V3Z" />
    </svg>`
  },
  ChangeReputationAction: {
    type: 'svg',
    icon: `<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="rgba(var(--a-dark), 1)">
    <path d="M22.5 10H15.75C15.13 10 14.6 10.38 14.37 10.91L12.11 16.2C12.04 16.37 12 16.56 12 16.75V18C12 18.2652 12.1054 18.5196 12.2929 18.7071C12.4804 18.8946 12.7348 19 13 19H18.18L17.5 22.18V22.42C17.5 22.73 17.63 23 17.83 23.22L18.62 24L23.56 19.06C23.83 18.79 24 18.41 24 18V11.5C24 11.1022 23.842 10.7206 23.5607 10.4393C23.2794 10.158 22.8978 10 22.5 10ZM12 6C12 5.73478 11.8946 5.48043 11.7071 5.29289C11.5196 5.10536 11.2652 5 11 5H5.82L6.5 1.82V1.59C6.5 1.28 6.37 1 6.17 0.79L5.38 0L0.44 4.94C0.17 5.21 0 5.59 0 6V12.5C0 12.8978 0.158035 13.2794 0.43934 13.5607C0.720644 13.842 1.10218 14 1.5 14H8.25C8.87 14 9.4 13.62 9.63 13.09L11.89 7.8C11.96 7.63 12 7.44 12 7.25V6Z"/>
  </svg>`
  },
  ChangePointsIntervalAction: {
    type: 'svg',
    icon: `
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="rgba(var(--a-dark), 1)">
    <path d="M1.38 17.9C0.78 17.71 0.3 17.23 0.0999999 16.63L16.63 0.0999999C17.23 0.3 17.71 0.78 17.9 1.38L1.38 17.9ZM17 13V15H10V13H17ZM0 3H3V0H5V3H8V5H5V8H3V5H0V3Z" />
  </svg>`
  },
  ChangeReputationIntervalAction: {
    type: 'svg',
    icon: `
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="rgba(var(--a-dark), 1)">
    <path d="M22.5 10H15.75C15.13 10 14.6 10.38 14.37 10.91L12.11 16.2C12.04 16.37 12 16.56 12 16.75V18C12 18.2652 12.1054 18.5196 12.2929 18.7071C12.4804 18.8946 12.7348 19 13 19H18.18L17.5 22.18V22.42C17.5 22.73 17.63 23 17.83 23.22L18.62 24L23.56 19.06C23.83 18.79 24 18.41 24 18V11.5C24 11.1022 23.842 10.7206 23.5607 10.4393C23.2794 10.158 22.8978 10 22.5 10ZM12 6C12 5.73478 11.8946 5.48043 11.7071 5.29289C11.5196 5.10536 11.2652 5 11 5H5.82L6.5 1.82V1.59C6.5 1.28 6.37 1 6.17 0.79L5.38 0L0.44 4.94C0.17 5.21 0 5.59 0 6V12.5C0 12.8978 0.158035 13.2794 0.43934 13.5607C0.720644 13.842 1.10218 14 1.5 14H8.25C8.87 14 9.4 13.62 9.63 13.09L11.89 7.8C11.96 7.63 12 7.44 12 7.25V6Z"/>
  </svg>`
  },
  SetReputationAction: {
    type: 'svg',
    icon: `
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.5 10H15.75C15.13 10 14.6 10.38 14.37 10.91L12.11 16.2C12.04 16.37 12 16.56 12 16.75V18C12 18.2652 12.1054 18.5196 12.2929 18.7071C12.4804 18.8946 12.7348 19 13 19H18.18L17.5 22.18V22.42C17.5 22.73 17.63 23 17.83 23.22L18.62 24L23.56 19.06C23.83 18.79 24 18.41 24 18V11.5C24 11.1022 23.842 10.7206 23.5607 10.4393C23.2794 10.158 22.8978 10 22.5 10ZM12 6C12 5.73478 11.8946 5.48043 11.7071 5.29289C11.5196 5.10536 11.2652 5 11 5H5.82L6.5 1.82V1.59C6.5 1.28 6.37 1 6.17 0.79L5.38 0L0.44 4.94C0.17 5.21 0 5.59 0 6V12.5C0 12.8978 0.158035 13.2794 0.43934 13.5607C0.720644 13.842 1.10218 14 1.5 14H8.25C8.87 14 9.4 13.62 9.63 13.09L11.89 7.8C11.96 7.63 12 7.44 12 7.25V6Z"/>
  </svg>`
  },
  SetPointsAction: {
    type: 'svg',
    icon: `
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.38 17.9C0.78 17.71 0.3 17.23 0.0999999 16.63L16.63 0.0999999C17.23 0.3 17.71 0.78 17.9 1.38L1.38 17.9ZM17 13V15H10V13H17ZM0 3H3V0H5V3H8V5H5V8H3V5H0V3Z" />
  </svg>`
  },
  ChangeTriggerActiveStateAction: {
    type: 'svg',
    icon: `
  <svg width="48" height="30" viewBox="0 0 42 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M27.5909 1H13.4091C6.55574 1 1 6.55574 1 13.4091C1 20.2624 6.55574 25.8182 13.4091 25.8182H27.5909C34.4443 25.8182 40 20.2624 40 13.4091C40 6.55574 34.4443 1 27.5909 1Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="rgba(var(--a-primary), 1)"/>
    <path d="M13.4091 18.7273C16.3462 18.7273 18.7273 16.3462 18.7273 13.4091C18.7273 10.4719 16.3462 8.09091 13.4091 8.09091C10.4719 8.09091 8.09091 10.4719 8.09091 13.4091C8.09091 16.3462 10.4719 18.7273 13.4091 18.7273Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M29.2222 6L22 14.4H28.5L27.7778 20L35 11.6H28.5L29.2222 6Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="rgba(var(--a-warning), 1)"/>
  </svg>`
  },
  ChangeXpIntervalAction: {
    type: 'svg',
    icon: `
  <svg width="30" height="30" viewBox="0 0 20 18" xmlns="http://www.w3.org/2000/svg" fill="rgba(var(--a-dark), 1)">
    <path d="M14.4626 16C14.2615 15.9363 14.1006 15.7754 14.0335 15.5743L19.5743 10.0335C19.7754 10.1006 19.9363 10.2615 20 10.4626L14.4626 16ZM19.6983 14.3575V15.0279H17.352V14.3575H19.6983ZM14 11.0056H15.0056V10H15.676V11.0056H16.6816V11.676H15.676V12.6816H15.0056V11.676H14V11.0056Z" />
    <path d="M3.80273 5.55078L5.5957 2.46875H7.30664L4.72852 6.69922L7.36523 11H5.63672L3.80273 7.87109L1.96289 11H0.240234L2.88281 6.69922L0.298828 2.46875H2.00977L3.80273 5.55078ZM13.875 7.89453C13.875 8.875 13.6523 9.6582 13.207 10.2441C12.7617 10.8262 12.1641 11.1172 11.4141 11.1172C10.7188 11.1172 10.1621 10.8887 9.74414 10.4316V13.4375H8.32031V4.66016H9.63281L9.69141 5.30469C10.1094 4.79688 10.6777 4.54297 11.3965 4.54297C12.1699 4.54297 12.7754 4.83203 13.2129 5.41016C13.6543 5.98438 13.875 6.7832 13.875 7.80664V7.89453ZM12.457 7.77148C12.457 7.13867 12.3301 6.63672 12.0762 6.26562C11.8262 5.89453 11.4668 5.70898 10.998 5.70898C10.416 5.70898 9.99805 5.94922 9.74414 6.42969V9.24219C10.002 9.73438 10.4238 9.98047 11.0098 9.98047C11.4629 9.98047 11.8164 9.79883 12.0703 9.43555C12.3281 9.06836 12.457 8.51367 12.457 7.77148Z" />
  </svg>`
  },
  DeleteMessageAction: {
    type: 'svg',
    icon: `
  <svg width="34" height="28" viewBox="0 0 30 24" xmlns="http://www.w3.org/2000/svg" fill="rgba(var(--a-danger), 1)">
    <path d="M21.6429 18.6667C21.6429 19.0203 21.7783 19.3594 22.0194 19.6095C22.2606 19.8595 22.5876 20 22.9286 20H28.0714C28.4124 20 28.7394 19.8595 28.9806 19.6095C29.2217 19.3594 29.3571 19.0203 29.3571 18.6667V10.6667H21.6429V18.6667ZM23.2243 13.92L24.1307 12.98L25.5 14.3933L26.8629 12.98L27.7693 13.92L26.4064 15.3333L27.7693 16.7467L26.8629 17.6867L25.5 16.2733L24.1371 17.6867L23.2307 16.7467L24.5936 15.3333L23.2243 13.92ZM27.75 8.66667L27.1071 8H23.8929L23.25 8.66667H21V10H30V8.66667H27.75Z" />
    <path d="M18 0C18.5304 0 19.0391 0.210714 19.4142 0.585786C19.7893 0.960859 20 1.46957 20 2V14C20 14.5304 19.7893 15.0391 19.4142 15.4142C19.0391 15.7893 18.5304 16 18 16H4L0 20V2C0 0.89 0.9 0 2 0H18ZM2 2V15.17L3.17 14H18V2H2ZM4 5H16V7H4V5ZM4 9H13V11H4V9Z" />
  </svg>`
  },
  ForwardMessageToTargetAction: {
    type: 'svg',
    icon: `
  <svg width="36" height="30" viewBox="0 0 38 30" xmlns="http://www.w3.org/2000/svg" fill="rgba(var(--a-dark), 1)">
    <path d="M9.11111 6.86667L9.11111 5L6 8.26667L9.11111 11.5333L9.11111 9.62C11.3333 9.62 12.8889 10.3667 14 12C13.5556 9.66667 12.2222 7.33333 9.11111 6.86667Z" />
    <path d="M29.1 9V10.863C25.842 11.277 23.277 13.842 22.863 17.1H21V18.9H22.863C23.277 22.158 25.842 24.723 29.1 25.137V27H30.9V25.137C34.158 24.723 36.723 22.158 37.137 18.9H39V17.1H37.137C36.723 13.842 34.158 11.277 30.9 10.863V9H29.1ZM29.1 12.672V14.4H30.9V12.681C33.15 13.05 34.95 14.85 35.328 17.1H33.6V18.9H35.319C34.95 21.15 33.15 22.95 30.9 23.328V21.6H29.1V23.319C26.85 22.95 25.05 21.15 24.672 18.9H26.4V17.1H24.681C25.05 14.85 26.85 13.05 29.1 12.672ZM30 17.1C29.7613 17.1 29.5324 17.1948 29.3636 17.3636C29.1948 17.5324 29.1 17.7613 29.1 18C29.1 18.2387 29.1948 18.4676 29.3636 18.6364C29.5324 18.8052 29.7613 18.9 30 18.9C30.2387 18.9 30.4676 18.8052 30.6364 18.6364C30.8052 18.4676 30.9 18.2387 30.9 18C30.9 17.7613 30.8052 17.5324 30.6364 17.3636C30.4676 17.1948 30.2387 17.1 30 17.1Z" />
    <path d="M18 0H2C0.9 0 0 0.9 0 2V20L4 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM18 14H3.2L2 15.2V2H18V14Z" />
    <path d="M20.3 20.6471L17.6 23.2941V21.5294H14V19.7647H17.6V18L20.3 20.6471Z" />
  </svg>`
  },
  ForwardReplyMessageToTargetAction: {
    type: 'svg',
    icon: `
  <svg width="30" height="30" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" fill="rgba(var(--a-dark), 1)">
    <path d="M25.7005 6.26098L26.9636 5.8594L25.5341 9.01814L22.5428 7.26494L23.8375 6.85332C23.2797 5.09905 22.3841 4.0317 21 3.50595C22.6904 3.35483 24.6039 3.90541 25.7005 6.26098Z" />
    <path d="M13.5 11H25.5C25.8978 11 26.2794 11.158 26.5607 11.4393C26.842 11.7206 27 12.1022 27 12.5V26L24 23H13.5C13.1022 23 12.7206 22.842 12.4393 22.5607C12.158 22.2794 12 21.8978 12 21.5V12.5C12 11.6675 12.675 11 13.5 11Z" />
    <path d="M18 0H2C0.9 0 0 0.9 0 2V20L4 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM18 14H3.2L2 15.2V2H18V14ZM14 9V7H9.5L11.3 5.2L10 4L6 8L10 12L11.2 10.8L9.5 9H14Z" />
    <path d="M5.95 19V20.1385C3.959 20.3915 2.3915 21.959 2.1385 23.95H1V25.05H2.1385C2.3915 27.041 3.959 28.6085 5.95 28.8615V30H7.05V28.8615C9.041 28.6085 10.6085 27.041 10.8615 25.05H12V23.95H10.8615C10.6085 21.959 9.041 20.3915 7.05 20.1385V19H5.95ZM5.95 21.244V22.3H7.05V21.2495C8.425 21.475 9.525 22.575 9.756 23.95H8.7V25.05H9.7505C9.525 26.425 8.425 27.525 7.05 27.756V26.7H5.95V27.7505C4.575 27.525 3.475 26.425 3.244 25.05H4.3V23.95H3.2495C3.475 22.575 4.575 21.475 5.95 21.244ZM6.5 23.95C6.35413 23.95 6.21424 24.0079 6.11109 24.1111C6.00795 24.2142 5.95 24.3541 5.95 24.5C5.95 24.6459 6.00795 24.7858 6.11109 24.8889C6.21424 24.9921 6.35413 25.05 6.5 25.05C6.64587 25.05 6.78576 24.9921 6.88891 24.8889C6.99205 24.7858 7.05 24.6459 7.05 24.5C7.05 24.3541 6.99205 24.2142 6.88891 24.1111C6.78576 24.0079 6.64587 23.95 6.5 23.95Z" />
  </svg>`
  },
  KickAction: {
    type: 'svg',
    icon: `
  <svg width="34" height="30" viewBox="0 0 36 28" xmlns="http://www.w3.org/2000/svg" fill="rgba(var(--a-danger), 1)">
    <path d="M34.53 0.268537C33.57 -0.281463 32.35 0.0385372 31.8 0.998537C31.24 1.94854 31.57 3.17854 32.53 3.72854C33.48 4.27854 34.7 3.95854 35.26 2.99854C35.81 2.03854 35.48 0.818537 34.53 0.268537ZM36.8 8.33854L34.23 12.7885L32.49 11.7885L34.22 8.78854L32.67 7.49854L29.53 12.9185V20.9985H27.53V12.3885L18 6.88854L19 5.15854L26.8 9.65854L29.2 5.49854L22.81 3.16854L23.53 1.28854L30.26 3.73854L30.53 3.83854C30.92 3.99854 31.29 4.14854 31.65 4.34854L32.49 4.83854C32.84 5.03854 33.18 5.27854 33.49 5.53854L33.72 5.73854L36.8 8.33854Z" />
    <path d="M18.2385 9.52315C18.0132 9.48606 17.7896 9.58597 17.6019 9.81027C17.4736 9.97048 17.456 10.0521 17.4966 10.3397C17.5498 10.741 17.994 11.7188 18.3202 12.1475C18.7608 12.7317 19.6241 13.2959 20.5197 13.5909L20.9102 13.7205L20.773 13.9613C20.6942 14.0895 20.5784 14.4431 20.5179 14.7374C20.321 15.6399 20.5495 16.6282 21.1251 17.4321L21.3493 17.7393L20.9692 17.9848C19.6424 18.8349 18.6754 20.4995 18.7638 21.7821C18.785 22.094 18.8131 22.1484 19.017 22.3118C19.2889 22.5296 19.5366 22.5687 19.768 22.4192C20.0411 22.2475 20.1034 22.1299 20.1494 21.7044C20.169 21.481 20.2421 21.141 20.3054 20.9525C20.6199 19.9928 21.5767 19.0375 22.5611 18.6943C22.945 18.5634 23.1453 18.3731 23.1999 18.106C23.2662 17.7844 23.1789 17.6347 22.6885 17.2419C22.3089 16.9297 22.2322 16.8364 22.0566 16.4884C21.8121 15.9976 21.7416 15.399 21.8567 14.8774C21.9298 14.5374 21.9852 14.4383 22.312 14.0305C22.7593 13.4721 22.8051 13.2856 22.5654 12.958C22.3985 12.7286 22.4393 12.7374 21.4464 12.5637C20.2418 12.3558 19.3961 11.5906 18.9103 10.2766C18.7319 9.78293 18.5455 9.57779 18.2385 9.52315Z" />
    <path d="M11.2848 7.26074C11.1916 7.21792 10.5923 7.00081 9.95315 6.78367C8.69332 6.3562 7.54123 6.0629 6.72516 5.96707C5.95379 5.87517 5.97713 5.84604 5.28585 7.80328L4.87789 8.95917L5.26913 9.37627C6.36131 10.5304 7.18228 11.8737 7.49039 13.0214C7.55821 13.275 7.60469 13.4956 7.59691 13.5053C7.58524 13.5199 7.47948 13.3235 7.3553 13.0806C6.85567 12.0824 6.00823 11.0606 5.0906 10.3652L4.52059 9.93244L4.26292 10.2043C3.58908 10.916 2.99365 12.0173 2.79868 12.8974L2.69142 13.4094L3.08372 13.7157C4.0528 14.4762 6.19839 15.3503 10.2732 16.6621C12.0032 17.2191 14.1024 17.7531 15.0963 17.8957C16.2611 18.0637 17.6388 18.0355 18.4308 17.8331L18.7019 17.7633L18.7955 17.3281C18.9233 16.721 18.9128 16.3857 18.7489 15.9834C18.616 15.6617 18.5704 15.6092 18.0946 15.228C17.5994 14.8312 17.5712 14.8166 17.1011 14.7269C16.8388 14.6762 16.4968 14.6254 16.3462 14.6243C16.0615 14.6114 15.5963 14.4459 15.3137 14.2514L15.1457 14.1327L14.8306 14.526C14.4922 14.9484 14.1471 15.2299 13.7379 15.4123C13.2743 15.6227 13.2209 15.58 13.4582 15.2041C13.6693 14.871 14.2405 13.6706 14.3486 13.3267C14.4343 13.0606 14.3811 12.6991 14.2239 12.4774C14.1152 12.3345 13.9559 12.2149 13.5937 12.0203C13.3247 11.8686 12.9722 11.6419 12.8119 11.5135C12.4381 11.214 11.7965 10.3731 11.6481 9.99117C11.491 9.57036 11.4789 8.97865 11.6125 8.26463C11.7549 7.54965 11.7229 7.46024 11.2848 7.26074Z" />
    <path d="M17.9167 17.6755C16.1172 17.8918 14.7814 17.7383 12.655 17.0789C11.6098 16.7517 7.28158 15.2289 6.24908 14.8162C5.76828 14.6303 4.79505 14.2331 4.08989 13.9392C3.38474 13.6453 2.7699 13.4077 2.72617 13.4126C2.68245 13.4174 2.56762 13.6602 2.44981 13.9963C2.18789 14.751 2.15083 14.9365 2.25278 15.0182C2.40815 15.1427 5.67479 16.2294 5.75353 16.1809C5.79921 16.1537 5.90527 15.9517 5.9919 15.7342C6.08242 15.5117 6.18166 15.3282 6.2215 15.3282C6.26037 15.3195 6.74216 15.4743 7.29 15.6661L8.28853 16.0118L9.19061 16.7266L10.0966 17.4365L10.7648 17.677C11.1378 17.8084 11.8256 18.0247 12.2957 18.1542C14.6387 18.8118 15.9755 18.9741 17.638 18.7994C18.3988 18.7155 18.4503 18.7009 18.5933 18.5126C18.7946 18.2514 18.9864 17.7035 18.9029 17.6286C18.868 17.5927 18.4278 17.6147 17.9167 17.6755Z" />
  </svg>`
  },
  MuteAction: {
    type: 'svg',
    icon: `
  <svg width="26" height="26" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg" fill="rgba(var(--a-danger), 1)">
    <path d="M11.706 8.5L6.506 3.3C6.966 3.11 7.466 3 8.006 3C10.206 3 12.006 4.79 12.006 7C12.006 7.53 11.896 8.04 11.706 8.5ZM15.756 10.63C17.776 8.61 17.776 5.56 15.756 3.36L14.076 5.05C14.916 6.23 14.916 7.76 14.076 8.94L15.756 10.63ZM19.066 14C22.966 10.11 23.006 4.05 19.066 0L17.436 1.63C20.206 4.65 20.206 9.19 17.436 12.37L19.066 14ZM1.396 0.73L0.116 2L4.136 6C4.056 6.34 4.006 6.66 4.006 7C4.006 9.21 5.796 11 8.006 11C8.336 11 8.666 10.94 8.976 10.86L9.936 13.12C9.866 14.31 11.546 17.32 9.806 19.83L9.006 15L9.936 13.12C9.316 13.05 8.666 13 8.006 13C7.326 13 6.676 13.05 6.056 13.12L7.006 15L6.186 19.83C4.276 17.07 4.006 14.6 4.006 13.54C1.606 14.24 0 15.5 0 17V21H16.006V17.88L19.846 21.73L21.116 20.46L1.396 0.73Z" />
  </svg>`
  },
  SendMessageAction: {
    type: 'svg',
    icon: `
  <svg width="30" height="24" viewBox="0 0 24 18" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 16H5C4.46957 16 3.96086 15.7893 3.58579 15.4142C3.21071 15.0391 3 14.5304 3 14V4.94L0.23 1.64C0.09 1.47 0 1.24 0 1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H18C18.5304 0 19.0391 0.210714 19.4142 0.585786C19.7893 0.960859 20 1.46957 20 2V14C20 14.5304 19.7893 15.0391 19.4142 15.4142C19.0391 15.7893 18.5304 16 18 16ZM6.5 3C6.36739 3 6.24021 3.05268 6.14645 3.14645C6.05268 3.24021 6 3.36739 6 3.5V4.5C6 4.63261 6.05268 4.75979 6.14645 4.85355C6.24021 4.94732 6.36739 5 6.5 5H16.5C16.6326 5 16.7598 4.94732 16.8536 4.85355C16.9473 4.75979 17 4.63261 17 4.5V3.5C17 3.36739 16.9473 3.24021 16.8536 3.14645C16.7598 3.05268 16.6326 3 16.5 3H6.5ZM6.5 7C6.36739 7 6.24021 7.05268 6.14645 7.14645C6.05268 7.24021 6 7.36739 6 7.5V8.5C6 8.63261 6.05268 8.75979 6.14645 8.85355C6.24021 8.94732 6.36739 9 6.5 9H16.5C16.6326 9 16.7598 8.94732 16.8536 8.85355C16.9473 8.75979 17 8.63261 17 8.5V7.5C17 7.36739 16.9473 7.24021 16.8536 7.14645C16.7598 7.05268 16.6326 7 16.5 7H6.5ZM6.5 11C6.36739 11 6.24021 11.0527 6.14645 11.1464C6.05268 11.2402 6 11.3674 6 11.5V12.5C6 12.6326 6.05268 12.7598 6.14645 12.8536C6.24021 12.9473 6.36739 13 6.5 13H11.5C11.6326 13 11.7598 12.9473 11.8536 12.8536C11.9473 12.7598 12 12.6326 12 12.5V11.5C12 11.3674 11.9473 11.2402 11.8536 11.1464C11.7598 11.0527 11.6326 11 11.5 11H6.5Z" />
  </svg>`
  },
  SendMessageToTargetAction: {
    type: 'svg',
    icon: `
  <svg width="38" height="28" viewBox="0 0 38 24" xmlns="http://www.w3.org/2000/svg" fill="rgba(var(--a-dark), 1)">
    <path d="M20.3 19.6471L17.6 22.2941V20.5294H14V18.7647H17.6V17L20.3 19.6471Z" />
    <path d="M29.1 8V9.863C25.842 10.277 23.277 12.842 22.863 16.1H21V17.9H22.863C23.277 21.158 25.842 23.723 29.1 24.137V26H30.9V24.137C34.158 23.723 36.723 21.158 37.137 17.9H39V16.1H37.137C36.723 12.842 34.158 10.277 30.9 9.863V8H29.1ZM29.1 11.672V13.4H30.9V11.681C33.15 12.05 34.95 13.85 35.328 16.1H33.6V17.9H35.319C34.95 20.15 33.15 21.95 30.9 22.328V20.6H29.1V22.319C26.85 21.95 25.05 20.15 24.672 17.9H26.4V16.1H24.681C25.05 13.85 26.85 12.05 29.1 11.672ZM30 16.1C29.7613 16.1 29.5324 16.1948 29.3636 16.3636C29.1948 16.5324 29.1 16.7613 29.1 17C29.1 17.2387 29.1948 17.4676 29.3636 17.6364C29.5324 17.8052 29.7613 17.9 30 17.9C30.2387 17.9 30.4676 17.8052 30.6364 17.6364C30.8052 17.4676 30.9 17.2387 30.9 17C30.9 16.7613 30.8052 16.5324 30.6364 16.3636C30.4676 16.1948 30.2387 16.1 30 16.1Z" />
    <path d="M18 16H5C4.46957 16 3.96086 15.7893 3.58579 15.4142C3.21071 15.0391 3 14.5304 3 14V4.94L0.23 1.64C0.09 1.47 0 1.24 0 1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H18C18.5304 0 19.0391 0.210714 19.4142 0.585786C19.7893 0.960859 20 1.46957 20 2V14C20 14.5304 19.7893 15.0391 19.4142 15.4142C19.0391 15.7893 18.5304 16 18 16ZM6.5 3C6.36739 3 6.24021 3.05268 6.14645 3.14645C6.05268 3.24021 6 3.36739 6 3.5V4.5C6 4.63261 6.05268 4.75979 6.14645 4.85355C6.24021 4.94732 6.36739 5 6.5 5H16.5C16.6326 5 16.7598 4.94732 16.8536 4.85355C16.9473 4.75979 17 4.63261 17 4.5V3.5C17 3.36739 16.9473 3.24021 16.8536 3.14645C16.7598 3.05268 16.6326 3 16.5 3H6.5ZM6.5 7C6.36739 7 6.24021 7.05268 6.14645 7.14645C6.05268 7.24021 6 7.36739 6 7.5V8.5C6 8.63261 6.05268 8.75979 6.14645 8.85355C6.24021 8.94732 6.36739 9 6.5 9H16.5C16.6326 9 16.7598 8.94732 16.8536 8.85355C16.9473 8.75979 17 8.63261 17 8.5V7.5C17 7.36739 16.9473 7.24021 16.8536 7.14645C16.7598 7.05268 16.6326 7 16.5 7H6.5ZM6.5 11C6.36739 11 6.24021 11.0527 6.14645 11.1464C6.05268 11.2402 6 11.3674 6 11.5V12.5C6 12.6326 6.05268 12.7598 6.14645 12.8536C6.24021 12.9473 6.36739 13 6.5 13H11.5C11.6326 13 11.7598 12.9473 11.8536 12.8536C11.9473 12.7598 12 12.6326 12 12.5V11.5C12 11.3674 11.9473 11.2402 11.8536 11.1464C11.7598 11.0527 11.6326 11 11.5 11H6.5Z" />
  </svg>`
  },
  SendPrivateMessageAction: {
    type: 'svg',
    icon: `
  <svg width="30" height="30" viewBox="0 0 26 20" xmlns="http://www.w3.org/2000/svg" fill="rgba(var(--a-dark), 1)">
    <path d="M10.8 10H3C2.68174 10 2.37652 9.8683 2.15147 9.63388C1.92643 9.39946 1.8 9.08152 1.8 8.75V3.0875L0.138 1.025C0.054 0.91875 0 0.775 0 0.625C0 0.45924 0.0632141 0.300269 0.175736 0.183058C0.288258 0.0658481 0.44087 0 0.6 0H10.8C11.1183 0 11.4235 0.131696 11.6485 0.366116C11.8736 0.600537 12 0.918479 12 1.25V8.75C12 9.08152 11.8736 9.39946 11.6485 9.63388C11.4235 9.8683 11.1183 10 10.8 10ZM3.9 1.875C3.82044 1.875 3.74413 1.90792 3.68787 1.96653C3.63161 2.02513 3.6 2.10462 3.6 2.1875V2.8125C3.6 2.89538 3.63161 2.97487 3.68787 3.03347C3.74413 3.09208 3.82044 3.125 3.9 3.125H9.9C9.97957 3.125 10.0559 3.09208 10.1121 3.03347C10.1684 2.97487 10.2 2.89538 10.2 2.8125V2.1875C10.2 2.10462 10.1684 2.02513 10.1121 1.96653C10.0559 1.90792 9.97957 1.875 9.9 1.875H3.9ZM3.9 4.375C3.82044 4.375 3.74413 4.40792 3.68787 4.46653C3.63161 4.52513 3.6 4.60462 3.6 4.6875V5.3125C3.6 5.39538 3.63161 5.47487 3.68787 5.53347C3.74413 5.59208 3.82044 5.625 3.9 5.625H9.9C9.97957 5.625 10.0559 5.59208 10.1121 5.53347C10.1684 5.47487 10.2 5.39538 10.2 5.3125V4.6875C10.2 4.60462 10.1684 4.52513 10.1121 4.46653C10.0559 4.40792 9.97957 4.375 9.9 4.375H3.9ZM3.9 6.875C3.82044 6.875 3.74413 6.90792 3.68787 6.96653C3.63161 7.02513 3.6 7.10462 3.6 7.1875V7.8125C3.6 7.89538 3.63161 7.97487 3.68787 8.03347C3.74413 8.09208 3.82044 8.125 3.9 8.125H6.9C6.97957 8.125 7.05587 8.09208 7.11213 8.03347C7.16839 7.97487 7.2 7.89538 7.2 7.8125V7.1875C7.2 7.10462 7.16839 7.02513 7.11213 6.96653C7.05587 6.90792 6.97957 6.875 6.9 6.875H3.9Z" />
    <path d="M10.6 17L13.3 14.3529L10.6 11.7059V13.4706H7V15.2353H10.6V17ZM17.8 2C16.8452 2 15.9295 2.37185 15.2544 3.03374C14.5793 3.69563 14.2 4.59335 14.2 5.52941C14.2 6.46547 14.5793 7.36319 15.2544 8.02508C15.9295 8.68698 16.8452 9.05882 17.8 9.05882C18.7548 9.05882 19.6705 8.68698 20.3456 8.02508C21.0207 7.36319 21.4 6.46547 21.4 5.52941C21.4 4.59335 21.0207 3.69563 20.3456 3.03374C19.6705 2.37185 18.7548 2 17.8 2ZM17.8 10.8235C16.765 10.8235 15.775 10.9294 14.884 11.1235C15.586 12.0147 16 13.1353 16 14.3529C16 14.9706 15.892 15.5618 15.694 16.1176H25V14.3529C25 12.4029 21.778 10.8235 17.8 10.8235Z" />
  </svg>`
  },
  TriggerWarnAction: {
    type: 'svg',
    icon: `
  <svg width="38" height="40" viewBox="0 0 32 28" xmlns="http://www.w3.org/2000/svg" fill="rgba(var(--a-warning), 1)">
    <path d="M9.73802 7.96274L1.26802 22.1027C1.09339 22.4052 1.00099 22.748 1.00001 23.0973C0.99903 23.4465 1.08951 23.7899 1.26245 24.0933C1.43538 24.3967 1.68474 24.6495 1.98573 24.8266C2.28671 25.0037 2.62882 25.0989 2.97802 25.1027H19.918C20.2672 25.0989 20.6093 25.0037 20.9103 24.8266C21.2113 24.6495 21.4607 24.3967 21.6336 24.0933C21.8065 23.7899 21.897 23.4465 21.896 23.0973C21.8951 22.748 21.8027 22.4052 21.628 22.1027L13.158 7.96274C12.9797 7.66885 12.7287 7.42586 12.4292 7.25723C12.1297 7.08859 11.7918 7 11.448 7C11.1043 7 10.7663 7.08859 10.4668 7.25723C10.1673 7.42586 9.91629 7.66885 9.73802 7.96274V7.96274Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.448 13.1028V17.1028" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.448 21.1028H11.458" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M26.2222 1L19 9.4H25.5L24.7778 15L32 6.6H25.5L26.2222 1Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>`
  },
  UnbanAction: {
    type: 'svg',
    icon: `
  <svg width="24" height="24" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="rgba(var(--a-success), 1)">
    <path d="M18.1 8.5L19.5 9.91L12.97 16.5L9.5 13L10.9 11.59L12.97 13.67L18.1 8.5ZM7 13L10 16H0V14C0 11.79 3.58 10 8 10L9.89 10.11L7 13ZM8 0C9.06087 0 10.0783 0.421427 10.8284 1.17157C11.5786 1.92172 12 2.93913 12 4C12 5.06087 11.5786 6.07828 10.8284 6.82843C10.0783 7.57857 9.06087 8 8 8C6.93913 8 5.92172 7.57857 5.17157 6.82843C4.42143 6.07828 4 5.06087 4 4C4 2.93913 4.42143 1.92172 5.17157 1.17157C5.92172 0.421427 6.93913 0 8 0V0Z" />
  </svg>`
  },
  UnmuteAction: {
    type: 'svg',
    icon: `
  <svg width="26" height="26" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg" fill="rgba(var(--a-success), 1)">
    <path d="M15.756 3.36C17.776 5.56 17.776 8.61 15.756 10.63L14.076 8.94C14.916 7.76 14.916 6.23 14.076 5.05L15.756 3.36ZM19.066 0C23.006 4.05 22.966 10.11 19.066 14L17.436 12.37C20.206 9.19 20.206 4.65 17.436 1.63L19.066 0ZM8.006 3C10.206 3 12.006 4.79 12.006 7C12.006 9.21 10.206 11 8.006 11C5.806 11 4.006 9.21 4.006 7C4.006 4.79 5.796 3 8.006 3ZM12.006 13.54C12.006 14.6 11.716 17.07 9.806 19.83L9.006 15L9.936 13.12C9.316 13.05 8.666 13 8.006 13C7.346 13 6.676 13.05 6.056 13.12L7.006 15L6.186 19.83C4.276 17.07 4.006 14.6 4.006 13.54C1.606 14.24 0 15.5 0 17V21H16.006V17C16.006 15.5 14.396 14.24 12.006 13.54Z" />
  </svg>`
  },
  // WarnAction: {
  //   type: 'svg',
  //   icon: `
  // <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="rgba(var(--a-warning), 1)">
  //   <path d="M9.73802 1.96274L1.26802 16.1027C1.09339 16.4052 1.00099 16.748 1.00001 17.0973C0.99903 17.4465 1.08951 17.7899 1.26245 18.0933C1.43538 18.3967 1.68474 18.6495 1.98573 18.8266C2.28671 19.0037 2.62882 19.0989 2.97802 19.1027H19.918C20.2672 19.0989 20.6093 19.0037 20.9103 18.8266C21.2113 18.6495 21.4607 18.3967 21.6336 18.0933C21.8065 17.7899 21.897 17.4465 21.896 17.0973C21.8951 16.748 21.8027 16.4052 21.628 16.1027L13.158 1.96274C12.9797 1.66885 12.7287 1.42586 12.4292 1.25723C12.1297 1.08859 11.7918 1 11.448 1C11.1043 1 10.7663 1.08859 10.4668 1.25723C10.1673 1.42586 9.91629 1.66885 9.73802 1.96274V1.96274Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  //   <path d="M11.448 7.10275V11.1028" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  //   <path d="M11.448 15.1028H11.458" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  // </svg>`
  // },
  SendFormToChat: {
    type: 'svg',
    icon: `
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.5 10V5.5C18.5 5.36739 18.4473 5.24021 18.3536 5.14645C18.2598 5.05268 18.1326 5 18 5H11.5C11.3674 5 11.2402 5.05268 11.1464 5.14645C11.0527 5.24021 11 5.36739 11 5.5V12.5L13 10.5H18C18.1326 10.5 18.2598 10.4473 18.3536 10.3536C18.4473 10.2598 18.5 10.1326 18.5 10ZM20.5 7H19.5V11.5H13V12.5C13 12.6326 13.0527 12.7598 13.1464 12.8536C13.2402 12.9473 13.3674 13 13.5 13H19L21 15V7.5C21 7.36739 20.9473 7.24021 20.8536 7.14645C20.7598 7.05268 20.6326 7 20.5 7Z" />
    <path d="M8.59999 17L11.3 14.3529L8.59999 11.7059V13.4706H4.99999V15.2353H8.59999V17Z" />
    <path d="M1.11111 0H8.88889C9.50556 0 10 0.495 10 1.1V9.9C10 10.5105 9.50556 11 8.88889 11H1.11111C0.5 11 0 10.5105 0 9.9V1.1C0 0.495 0.5 0 1.11111 0ZM1.11111 1.1V3.3H8.88889V1.1H1.11111ZM1.11111 9.9H8.88889V4.4H1.11111V9.9ZM2.22222 5.5H7.77778V6.6H2.22222V5.5ZM2.22222 7.7H7.77778V8.8H2.22222V7.7Z" />
    <path d="M8.67723 1.65H6.73279V2.75H8.67723V1.65Z" />
  </svg>`
  },
  SendFormToPrivate: {
    type: 'svg',
    icon: `
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.6 17L11.3 14.3529L8.6 11.7059V13.4706H5V15.2353H8.6V17ZM15.8 2C14.8452 2 13.9295 2.37185 13.2544 3.03374C12.5793 3.69563 12.2 4.59335 12.2 5.52941C12.2 6.46547 12.5793 7.36319 13.2544 8.02508C13.9295 8.68698 14.8452 9.05882 15.8 9.05882C16.7548 9.05882 17.6705 8.68698 18.3456 8.02508C19.0207 7.36319 19.4 6.46547 19.4 5.52941C19.4 4.59335 19.0207 3.69563 18.3456 3.03374C17.6705 2.37185 16.7548 2 15.8 2ZM15.8 10.8235C14.765 10.8235 13.775 10.9294 12.884 11.1235C13.586 12.0147 14 13.1353 14 14.3529C14 14.9706 13.892 15.5618 13.694 16.1176H23V14.3529C23 12.4029 19.778 10.8235 15.8 10.8235Z" />
    <path d="M1.11111 0H8.88889C9.50556 0 10 0.495 10 1.1V9.9C10 10.5105 9.50556 11 8.88889 11H1.11111C0.5 11 0 10.5105 0 9.9V1.1C0 0.495 0.5 0 1.11111 0ZM1.11111 1.1V3.3H8.88889V1.1H1.11111ZM1.11111 9.9H8.88889V4.4H1.11111V9.9ZM2.22222 5.5H7.77778V6.6H2.22222V5.5ZM2.22222 7.7H7.77778V8.8H2.22222V7.7Z" />
    <path d="M8.67723 1.65H6.73279V2.75H8.67723V1.65Z" />
  </svg>`
  },
  SendFormResultToTarget: {
    type: 'svg',
    icon: `
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.1414 13.2759L8.55863 14.8276V13.7931H6.44828V12.7586H8.55863V11.7241L10.1414 13.2759Z" />
    <path d="M15.3 6.44828V7.54038C13.3901 7.78307 11.8865 9.28669 11.6438 11.1966H10.5517V12.2517H11.6438C11.8865 14.1616 13.3901 15.6652 15.3 15.9079V17H16.3552V15.9079C18.265 15.6652 19.7687 14.1616 20.0113 12.2517H21.1035V11.1966H20.0113C19.7687 9.28669 18.265 7.78307 16.3552 7.54038V6.44828H15.3ZM15.3 8.60083V9.61379H16.3552V8.6061C17.6741 8.82241 18.7293 9.87759 18.9509 11.1966H17.9379V12.2517H18.9456C18.7293 13.5707 17.6741 14.6259 16.3552 14.8474V13.8345H15.3V14.8422C13.981 14.6259 12.9259 13.5707 12.7043 12.2517H13.7172V11.1966H12.7096C12.9259 9.87759 13.981 8.82241 15.3 8.60083ZM15.8276 11.1966C15.6877 11.1966 15.5535 11.2521 15.4545 11.3511C15.3556 11.45 15.3 11.5842 15.3 11.7241C15.3 11.8641 15.3556 11.9983 15.4545 12.0972C15.5535 12.1961 15.6877 12.2517 15.8276 12.2517C15.9675 12.2517 16.1017 12.1961 16.2006 12.0972C16.2996 11.9983 16.3552 11.8641 16.3552 11.7241C16.3552 11.5842 16.2996 11.45 16.2006 11.3511C16.1017 11.2521 15.9675 11.1966 15.8276 11.1966Z" />
    <path d="M1.10728 0H8.85824C9.47278 0 9.96552 0.501207 9.96552 1.11379V10.0241C9.96552 10.6423 9.47278 11.1379 8.85824 11.1379H1.10728C0.498276 11.1379 0 10.6423 0 10.0241V1.11379C0 0.501207 0.498276 0 1.10728 0ZM1.10728 1.11379V3.34138H8.85824V1.11379H1.10728ZM1.10728 10.0241H8.85824V4.45517H1.10728V10.0241ZM2.21456 5.56897H7.75096V6.68276H2.21456V5.56897ZM2.21456 7.79655H7.75096V8.91035H2.21456V7.79655Z" />
    <path d="M8.64731 1.67069H6.70957V2.78448H8.64731V1.67069Z" />
  </svg>`
  },
  SendMessageToCalculatedTargetAction: {
    type: 'svg',
    icon: `
  <svg xmlns="http://www.w3.org/2000/svg" height="30" width="30" viewBox="0 0 24 24" fill="rgba(var(--a-danger), 1)">
      <path d="M20,4H4C2.9,4,2,4.9,2,6v12c0,1.1,0.9,2,2,2h9v-2H4V8l8,5l8-5v5h2V6C22,4.9,21.1,4,20,4z M12,11L4,6h16L12,11z M19,15l4,4 l-4,4v-3h-4v-2h4V15z"/>
  </svg>`
  },
  CalculatePropertyTriggerAction: {
    type: 'svg',
    icon: `
  <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="rgba(var(--a-danger), 1)">
    <path d="M19,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M13.03,7.06L14.09,6l1.41,1.41 L16.91,6l1.06,1.06l-1.41,1.41l1.41,1.41l-1.06,1.06L15.5,9.54l-1.41,1.41l-1.06-1.06l1.41-1.41L13.03,7.06z M6.25,7.72h5v1.5h-5 V7.72z M11.5,16h-2v2H8v-2H6v-1.5h2v-2h1.5v2h2V16z M18,17.25h-5v-1.5h5V17.25z M18,14.75h-5v-1.5h5V14.75z"/>
  </svg>`
  },
  WarnActionsWrapper: {
    type: 'svg',
    icon: `
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="rgba(var(--a-warning), 1)">
    <path d="M9.73802 1.96274L1.26802 16.1027C1.09339 16.4052 1.00099 16.748 1.00001 17.0973C0.99903 17.4465 1.08951 17.7899 1.26245 18.0933C1.43538 18.3967 1.68474 18.6495 1.98573 18.8266C2.28671 19.0037 2.62882 19.0989 2.97802 19.1027H19.918C20.2672 19.0989 20.6093 19.0037 20.9103 18.8266C21.2113 18.6495 21.4607 18.3967 21.6336 18.0933C21.8065 17.7899 21.897 17.4465 21.896 17.0973C21.8951 16.748 21.8027 16.4052 21.628 16.1027L13.158 1.96274C12.9797 1.66885 12.7287 1.42586 12.4292 1.25723C12.1297 1.08859 11.7918 1 11.448 1C11.1043 1 10.7663 1.08859 10.4668 1.25723C10.1673 1.42586 9.91629 1.66885 9.73802 1.96274V1.96274Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.448 7.10275V11.1028" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.448 15.1028H11.458" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>`
  },
  [ActionsWrapperType.AchievementsActionsWrapper]: {
    type: 'material-icons',
    icon: 'auto_fix_high',
    color: 'cadetblue',
    size: '40px'
  },
  [ActionsType.CompleteCaptchaQuestAction]: {
    type: 'svg',
    icon: `<svg fill="#4e9a06" width="40px" height="40px" viewBox="-13.22 0 122.88 122.88" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="enable-background:new 0 0 96.43 122.88" xml:space="preserve"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <style type="text/css">.st0{fill-rule:evenodd;clip-rule:evenodd;}</style> <g> <path class="st0" d="M0,115.27h4.39V1.99V0h1.99h82.93h1.99v1.99v113.28h5.14v7.61H0V115.27L0,115.27z M13.88,8.32H81.8h0.83v0.83 v104.89h4.69V3.97H8.36v111.3h4.69V9.15V8.32H13.88L13.88,8.32z M15.94,114.04H75.1l-0.38-0.15l-27.76-3.79V33.9l32.79-20.66v-2.04 H15.94V114.04L15.94,114.04z M51.7,59.66l4.23-1.21v15.81l-4.23-1.53V59.66L51.7,59.66z"></path> </g> </g></svg>`,
  }
}
