






















import { ConditionSetupView } from "@/components/TriggerSetup/logic/mixins/condition-setup-view.mixin";
import AdvancedConditionSettings
  from "@/components/TriggerSetup/components/AdvancedConditionSettings/AdvancedConditionSettings.vue";
import ConditionInvertSettings
  from '@/components/TriggerSetup/components/ConditionInvertSettings/ConditionInvertSettings.vue'

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  'components': {
    AdvancedConditionSettings,
    ConditionInvertSettings
  }
})
export default class CallerConditionSetupView extends Mixins<ConditionSetupView>(ConditionSetupView) {

}
