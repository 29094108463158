





































































































import BindChannelApi from "@/includes/Api/BindChannel.api";
import { errorNotification } from "@/includes/NotificationService";
import { InputSetups } from "@/mixins/input-setups";

import { TargetCardInfo } from "piramis-base-components/src/components/TargetCard/types";
import TextInput from 'piramis-base-components/src/components/Pi/fields/TextInput/TextInput.vue';
import TargetCard from "piramis-base-components/src/components/TargetCard/TargetCard.vue";
import NestedContent from "piramis-base-components/src/components/Pi/components/NestedContent.vue";
import BindTopicInput from "piramis-base-components/src/components/BindTopicInput.vue";

import { Component, VModel, Mixins, Prop, PropSync } from 'vue-property-decorator';
import { ValidationObserver } from "vee-validate";

@Component({
  components: {
    ValidationObserver,
    TextInput,
    TargetCard,
    NestedContent,
    BindTopicInput
  }
})
export default class TriggerActionBindTarget extends Mixins(InputSetups) {

  @VModel() target!: number | string

  @PropSync('thread') threadId!: string

  @Prop() disabled!: boolean

  @Prop({ type: Boolean, default: false, required: false }) hasThreadBind!: boolean

  bindTarget: { id: string, info: TargetCardInfo | null } = {
    id: '',
    info: null,
  }

  isChecking = false

  isBindTopicModalOpen = false

  setBindTargetInfo(target: TargetCardInfo) {
    const { type, id, title, login, error } = target

    this.bindTarget.info = { id, type, title, login, error }
  }

  checkBindChannel(id: string | number, showErrorNotify = true) {
    const trimTarget = id.toString().trim()

    if (trimTarget) {
      this.isChecking = true

      return BindChannelApi.checkBindChannel('tg', {
        chat_id: this.$store.getters.chatId,
        channel: trimTarget
      })
        .then(({ data }) => {
          this.target = data.id
          this.bindTarget.id = ''

          this.setBindTargetInfo({
            id: data.id,
            type: data.source_type ?? data.type,
            title: data.name,
            login: data?.login ?? null,
            error: null
          })

          if (data.source_type !== 'FORUM' && this.threadId) {
            this.threadId = ''
          }
        })
        .catch(err => {
          if (showErrorNotify) {
            errorNotification(err)
          }

          this.target = trimTarget;

          this.setBindTargetInfo({
            id,
            error: err?.response?.data?.message ?? err?.message,
            title: null,
            type: null,
          })
        })
        .finally(() => {
          this.isChecking = false
        })
    }
  }

  created() {
    if (this.target) {
      this.checkBindChannel(this.target, false)
    }
  }
}
