
















































import { ConditionSetupView } from "@/components/TriggerSetup/logic/mixins/condition-setup-view.mixin";
import ConditionInvertSettings
  from '@/components/TriggerSetup/components/ConditionInvertSettings/ConditionInvertSettings.vue'
import SelectOptionsMixin from "@/mixins/SelectOptionsMixin";
import { ReactionUniqType } from "./types";

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  'components': {
    ConditionInvertSettings
  }
})
export default class ReactionCountConditionSetupView extends Mixins(ConditionSetupView, SelectOptionsMixin) {
  get uniq() {
    return this.conditionData.value[0] === ReactionUniqType.uniq
  }

  set uniq(val) {
    const type = val ? ReactionUniqType.uniq : ReactionUniqType.upper

    this.conditionData.value.splice(0, 1, type)
  }

  get count() {
    return this.conditionData.value[1]
  }

  set count(val) {
    this.conditionData.value.splice(1, 1, val)
  }

  get emoji() {
    return this.conditionData.value.slice(2)
  }

  set emoji(val) {
    this.conditionData.value.splice(2)
    this.conditionData.value.push(...val)
  }
}
