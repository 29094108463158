











import { ActionSetupView } from "@/components/TriggerSetup/logic/mixins/action-setup-view.mixin";
import TriggerActionBindTarget from "../../components/TriggerActionBindTarget.vue";

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  'components': {
    TriggerActionBindTarget
  }
})
export default class ForwardReplyMessageToTargetActionSetupView extends Mixins<ActionSetupView>(ActionSetupView) {
}
