


















import { ActionSetupView } from "@/components/TriggerSetup/logic/mixins/action-setup-view.mixin";
import ActionChance from "@/components/TriggerSetup/components/ActionChance/ActionChance.vue";
import ActionText from "@/components/TriggerSetup/components/ActionText/ActionText.vue";
import ActionUsers from "@/components/TriggerSetup/components/ActionUsers/ActionUsers.vue";
import Placeholders from "@/mixins/placeholders/placeholders";

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  'components': {
    ActionChance,
    ActionText,
    ActionUsers
  }
})
export default class SendFormToPrivateSetupView extends Mixins<ActionSetupView, Placeholders>(ActionSetupView, Placeholders) {

}
