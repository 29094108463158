


















import { ConditionSetupView } from "@/components/TriggerSetup/logic/mixins/condition-setup-view.mixin";

import { Component, Mixins } from 'vue-property-decorator'

@Component
export default class ConditionFiltersSettings extends Mixins<ConditionSetupView>(ConditionSetupView) {

}
