






















































import { TriggerHelper } from '@/components/TriggerSetup/logic/mixins/TriggerSetupMixin'
import isMobile from '@/assets/utils/isMobile'

import DefaultList from 'piramis-base-components/src/components/Pi/components/DefaultList/DefaultList.vue'
import { ListDirection } from 'piramis-base-components/src/components/Pi/components/List/logic/types'
import { UseFields } from 'piramis-base-components/src/components/Pi/index'
import Tags from 'piramis-base-components/src/components/Tags/Tags.vue'
import { Entity, Item, } from 'piramis-base-components/src/components/Pi/components/DefaultList/logic/types'
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import { SelectOptionData } from "piramis-base-components/src/components/Pi/types";
import PiTabs from 'piramis-base-components/src/components/PiTabs.vue'

import { Component, Emit, Mixins, Prop } from 'vue-property-decorator'
import { snakeCase } from 'lodash'

@Component({
  components: {
    DefaultList,
    Tags,
    ConfigField,
    PiTabs
  },
  data() {
    return {
      ListDirection,
    }
  },
  methods: {
    snakeCase,
    isMobile
  }
})
export default class EntitySelect extends Mixins(UseFields, TriggerHelper) {
  @Prop({ }) list!: Array<Entity>

  @Prop({ 'type': String, 'required': true, }) readonly buttonTitle!: string

  @Prop({ 'type': String, 'required': true, }) readonly popupTitleKey!: string

  @Prop({ 'type': Boolean, 'default': false }) readonly disabled!: boolean

  @Prop() tabs!: { show: boolean, tabs: Array<SelectOptionData>, currentTab: string | null, title: string }

  @Emit()
  select(value: Item): Item['key'] {
    this.isPopupOpen = false
    return value.key
  }

  @Emit()
  tabClick(tab: string | null) {
    return tab
  }

  @Emit()
  afterModalClose() {
    return null
  }

  get currentTab() {
    return this.tabs.currentTab
  }

  set currentTab(val) {
    this.tabs.currentTab = val
  }

  isPopupOpen = false

  get modalTarget() {
    return () => document.querySelector('.ant-modal-wrap')
  }
}
