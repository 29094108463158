var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-login-conditions-wrapper"},[_c('select-input',{attrs:{"setup":{
      'func': _vm.conditionInputSetupFn,
      'args': {
        'model': this,
        'key': 'type',
        'prefix': 'trigger_condition_',
        'validation': 'required',
        'options': _vm.conditionOptions,
        'clearable': false,
        disabled: _vm.disabled,
      }
    }}}),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }