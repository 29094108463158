import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import WarnActionSetupView from "@/components/TriggerSetup/actions/WarnAction/WarnActionSetupView.vue";
import { ActionsType } from "@/components/TriggerSetup/logic/types/types";
import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";

const action: EntityData = {
  'setupView': WarnActionSetupView,
  'structure': {
    'type': ActionsType.WarnAction,
    'target': "Caller",
    'count' : 1,
    'chance': 100,
  },
  ...entityDataTitleKeyFields(ActionsType.WarnAction, 'trigger_action_'),
}

export default action
