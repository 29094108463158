import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { ConditionsWrapperType } from "@/components/TriggerSetup/logic/types/types";

import FirstUserActivityLowerThat from "./conditions/FirstUserActivityLowerThat";
import FirstUserActivityMoreThat from "./conditions/FirstUserActivityMoreThat";
import FirstUserActivityConditionsWrapper from "./FirstUserActivityConditionsWrapper.vue";
import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";

const condition: EntityData = {
  'setupView': FirstUserActivityConditionsWrapper,
  ...entityDataTitleKeyFields(ConditionsWrapperType.FirstUserActivityConditionsWrapper, 'trigger_condition_'),
  structure: FirstUserActivityMoreThat.structure,
  extraProps: {
    conditions: [
      FirstUserActivityMoreThat,
      FirstUserActivityLowerThat,
    ],
  }
}

export default condition
