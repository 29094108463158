






























































































































import { TriggerData } from '@/components/TriggerSetup/logic/types/types'
import ActionsGroups from '@/components/TriggerSetup/components/ActionsGroups/ActionsGroups.vue'
import { TriggerHelper } from '@/components/TriggerSetup/logic/mixins/TriggerSetupMixin'
import CenteredColumnLayout from '@/components/CenteredColumnLayout.vue'
import { InputSetups } from '@/mixins/input-setups'
import { errorNotification } from '@/includes/NotificationService'
import TriggerAdvancedValidateMixin
  from '@/components/TriggerSetup/components/TriggerAdvancedValidate/TriggerAdvancedValidateMixin.vue'

import Overlay from 'piramis-base-components/src/components/Overlay/Overlay.vue'
import { UseFields } from 'piramis-base-components/src/components/Pi/index'
import { MessageEditorWithMediaData } from 'piramis-base-components/src/components/MessageEditorWithMedia/types'
import { SelectOption } from 'piramis-base-components/src/logic/types'
import HelpMessage from 'piramis-base-components/src/components/HelpMessage/HelpMessage.vue'

import { Component, Mixins, Prop, VModel, Watch } from 'vue-property-decorator'
import { cloneDeep } from 'lodash'
import { ValidationObserver } from 'vee-validate'

@Component({
  'components': {
    TriggerAdvancedValidateMixin,
    ValidationObserver,
    ActionsGroups,
    Overlay,
    CenteredColumnLayout,
    HelpMessage
  }
})
export default class TriggerAdvancedFormActionsSettings extends Mixins<UseFields, InputSetups, TriggerHelper>(UseFields, InputSetups, TriggerHelper) {
  @VModel({ 'type': Object, 'required': true }) model!: TriggerData

  @Prop({ 'type': Boolean, 'default': false }) readonly disabled!: boolean

  @Prop() readonly filteredActions!: any

  modelCopy: TriggerData | null = null

  isPopupOpen: boolean = false

  setEditorFormStructure: Array<MessageEditorWithMediaData> = []

  async setFormsOptions():Promise<SelectOption> {
    const forms = this.$store.state.chatState.questionnaire.forms

    if (forms && forms.length) {
      return this.$store.getters.formsSelectOptions
    } else {
      return this.$store.dispatch('getForms')
        .then(() => this.$store.getters.formsSelectOptions)
    }
  }

  get formActionsGroups(): any {
    if (this.modelCopy && this.modelCopy.formActions !== null) {
      return this.modelCopy.formActions
    } else {
      this.modelCopy!.formActions = []
      return this.modelCopy!.formActions
    }
  }

  set formActionsGroups(value: any) {
    (this.modelCopy as TriggerData).formActions = value
  }

  @Watch('modelCopy.form', { deep: true })
  onFormSelectChange(formGuid: string): void {
    if (formGuid && this.$store.state.chatState.questionnaire.forms) {
      this.getFormTextTemplate(formGuid)
        .then(res => {
          this.$emit('editorFormStructureChange', res.slice())
          this.setEditorFormStructure = res.slice()
        })
        .catch(errorNotification)
    } else {
      this.$emit('editorFormStructureChange', [])
      this.setEditorFormStructure = []
    }
  }

  getFormTextTemplate(formGuid: string): Promise<any> {
    return new Promise((resolve) => {
      let templateString = ''
      const formTemplate = this.$store.state.chatState.questionnaire.forms.find((form: any) => form.guid === formGuid)
      formTemplate.fields.forEach((field: any) => {
        templateString += `%form_${ field.name }_label%:  %form_${ field.name }%\n`
      })
      resolve([
        {
          text: templateString,
          remove_after: 0,
          send_after: 0,
          pin: false,
          disable_link_preview: false,
          disable_notify: false,
          protect_content: false,
          remove_previous: false,
          buttons: [],
          attachments: [],
          topics: []
        },
      ])
    })
  }

  get isModified(): boolean {
    return Boolean(
      this.model.formActions
        && this.model.formActions.length
        || this.model.form
        || this.model.multiplyForm
    )
  }

  openPopup(): void {
    this.modelCopy = cloneDeep(this.model)
    this.isPopupOpen = true
  }

  saveChanges(): void {
    this.model = this.modelCopy as TriggerData
    this.isPopupOpen = false
  }

  created() {
    if (!this.getTagsByFieldKey('forms')) {
      this.$store.dispatch('getForms')
    }
  }
}
