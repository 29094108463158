import { OldTriggerCondition, NewTriggerCondition } from "@/components/TriggerSetup/logic/types/types";

export const baseCondition: Omit<OldTriggerCondition, 'type'> = {
  value      : [],
  filters    : [ "Trim", "CaseInsensitive" ],
  caller     : "Anybody",
  messageType: "Any",
  invert     : false,
  version    : 'v1'
}

export const newBaseCondition: Omit<NewTriggerCondition, 'condition_type'> = {
  value         : [],
  filters       : [ "Trim", "CaseInsensitive" ],
  caller        : "Anybody",
  messageType   : "Any",
  invert        : false,
  placeholder   : null,
  condition_zone: null,
  version       : 'v2'
}
