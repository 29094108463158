import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { ConditionsWrapperType } from "../../../logic/types/types";
import MessageTextConditionsWrapper from "./MessageWordConditionsWrapper.vue";

import MessageWordStartWith from "./conditions/MessageWordStartWith";
import MessageWordEndWith from "./conditions/MessageWordEndWith";
import MessageWordFullMatch from "./conditions/MessageWordFullMatch";
import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";

const condition: EntityData = {
  'setupView': MessageTextConditionsWrapper,
  ...entityDataTitleKeyFields(ConditionsWrapperType.MessageWordConditionsWrapper, 'trigger_condition_'),
  structure: MessageWordFullMatch.structure,
  extraProps: {
    conditions: [
      MessageWordFullMatch,
      MessageWordEndWith,
      MessageWordStartWith,
    ],
  }
}

export default condition
