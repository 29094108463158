






















import { ActionSetupView } from "@/components/TriggerSetup/logic/mixins/action-setup-view.mixin";
import ActionChance from "@/components/TriggerSetup/components/ActionChance/ActionChance.vue";
import ActionText from "@/components/TriggerSetup/components/ActionText/ActionText.vue";
import Placeholders from "@/mixins/placeholders/placeholders";
import TriggerActionBindTarget from "@/components/TriggerSetup/components/TriggerActionBindTarget.vue";

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  'components': {
    TriggerActionBindTarget,
    ActionChance,
    ActionText
  }
})
export default class SendFormResultToTargetSetupView extends Mixins(ActionSetupView, Placeholders) {
}
