







































































































import { InputSetups } from '../../../mixins/input-setups'
import { TriggerHelper } from "@/components/TriggerSetup/logic/mixins/TriggerSetupMixin";
import TariffsTagsHelper from "@/mixins/TariffsTagsHelper";

import { UseFields } from 'piramis-base-components/src/components/Pi'
import Accordion from 'piramis-base-components/src/components/Accordion/Accordion.vue'

import { Component, Mixins, Prop, VModel } from 'vue-property-decorator'
import TimezoneSelect from "@/components/TimezoneSelect.vue";

@Component({
  components: {
    TimezoneSelect,
    Accordion,
  }
})
export default class TriggerExtraSettings extends Mixins<UseFields, InputSetups, TriggerHelper, TariffsTagsHelper>(UseFields, InputSetups, TriggerHelper, TariffsTagsHelper) {
  @VModel() triggerData!:any

  @Prop() disabled!:boolean

  @Prop({ 'type': Boolean, required: false, default: true }) checkLicense!: boolean

  isExtraSettingsOpen = false

  get areExtraSettingsEnabled(): boolean {
    return Boolean(this.triggerData.transparent
      || this.triggerData.chance !== 100
      || this.triggerData.delay)
  }

  mounted():void {
    if (this.areExtraSettingsEnabled) {
      this.isExtraSettingsOpen = true
    }
  }
}
