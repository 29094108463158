

































































































































































import ActionsGroups from '@/components/TriggerSetup/components/ActionsGroups/ActionsGroups.vue'
import { TriggerHelper, TriggerInputsSetups } from '@/components/TriggerSetup/logic/mixins/TriggerSetupMixin'
import { TriggerData } from '@/components/TriggerSetup/logic/types/types'
import CenteredColumnLayout from '@/components/CenteredColumnLayout.vue'
import { EntityData } from '@/components/TriggerSetup/logic/types/entity-data.type'
import { InputSetups } from '@/mixins/input-setups'
import TriggerAdvancedValidateMixin
  from '@/components/TriggerSetup/components/TriggerAdvancedValidate/TriggerAdvancedValidateMixin.vue'

import Overlay from 'piramis-base-components/src/components/Overlay/Overlay.vue'
import { UseFields } from 'piramis-base-components/src/components/Pi/index'
import HelpMessage from 'piramis-base-components/src/components/HelpMessage/HelpMessage.vue'
import NestedContent from 'piramis-base-components/src/components/Pi/components/NestedContent.vue'

import { Component, Mixins, Prop, VModel } from 'vue-property-decorator'
import { ValidationObserver } from 'vee-validate'
import { cloneDeep } from 'lodash'

@Component({
  'components': {
    ValidationObserver,
    ActionsGroups,
    Overlay,
    CenteredColumnLayout,
    TriggerAdvancedValidateMixin,
    HelpMessage,
    NestedContent
  }
})
export default class TriggerAdvancedChatUserLimitsSettings extends Mixins<UseFields, InputSetups, TriggerHelper, TriggerInputsSetups>(UseFields, InputSetups, TriggerHelper, TriggerInputsSetups) {
  @VModel({ 'type': Object, 'required': true }) model!: TriggerData

  @Prop({ 'type': Boolean, 'default': false }) readonly disabled!: boolean

  @Prop({ 'type': Array, 'required': true }) filteredActions!: Array<Array<EntityData>>

  @Prop({ 'type': Boolean, 'required': false, 'default': false }) hasForm!: boolean

  modelCopy: TriggerData | null = null

  isPopupOpen: boolean = false

  get globalLimitActionsGroups(): any {
    return this.modelCopy ? this.modelCopy.globalLimitActions : []
  }

  set globalLimitActionsGroups(value: any) {
    (this.modelCopy as TriggerData).globalLimitActions = value
  }

  get isModified(): boolean {
    return this.model.globalLimit > 0
  }

  openPopup(): void {
    this.modelCopy = cloneDeep(this.model)
    this.isPopupOpen = true
  }

  saveChanges(): void {
    this.model = this.modelCopy as TriggerData
    this.isPopupOpen = false
  }
}
