import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { newBaseCondition } from "@/components/TriggerSetup/logic/structures/base-condition.structure";
import {
  ConditionsType,
  ConditionZone,
  TriggerCondition
} from "@/components/TriggerSetup/logic/types/types";
import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";
import ReactionCountConditionSetupView
  from "@/components/TriggerSetup/conditions/new/ReactionsConditionsWrapper/conditions/ReactionCount/ReactionCountConditionSetupView.vue";
import { ReactionUniqType } from "./types";
import store from "@/store/store";
import { ULTIMATE_TAG } from "@/includes/constants";

const condition: EntityData = {
  'setupView': ReactionCountConditionSetupView,
  'structure': {
    'condition_type': ConditionsType.ReactionCount,
    ...newBaseCondition,
    value: [ ReactionUniqType.uniq , 2 ]
  } as TriggerCondition,
  ...entityDataTitleKeyFields(ConditionsType.ReactionCount, 'trigger_condition_'),
  extraProps: {
    possibleConditionZone: [ ConditionZone.ReactionMessage ]
  },
  limited: () => store.getters.getHaveLicense && store.getters.isChatLicenseExists ? null : ULTIMATE_TAG
}

export default condition
