


















import { ActionSetupView } from "@/components/TriggerSetup/logic/mixins/action-setup-view.mixin";

import { Component, Mixins, Prop } from 'vue-property-decorator'

@Component
export default class ActionTargets extends Mixins<ActionSetupView>(ActionSetupView) {
  @Prop() targetCustomHelpMessage!: string
}
