import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { newBaseCondition } from "@/components/TriggerSetup/logic/structures/base-condition.structure";
import MessageLangConditionSetupView from "./MessageLangConditionSetupView.vue";
import { ConditionsType, ConditionZone, TriggerCondition } from "@/components/TriggerSetup/logic/types/types";
import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";
import { ADVANCED_TAG, AI_EXPERIMENTAL_TAG } from "@/includes/constants";
import store from "@/store/store";

const condition: EntityData = {
  'setupView': MessageLangConditionSetupView,
  'structure': {
    'condition_type': ConditionsType.MessageLang,
    ...newBaseCondition,
    value: [ 1,  ]
  } as TriggerCondition,
  ...entityDataTitleKeyFields(ConditionsType.MessageLang, 'trigger_condition_'),
  extraProps: {
    possibleConditionZone: [ ConditionZone.Message ]
  },
  limited: () => {
    return store.getters.isChatSet && (!store.getters.isChatLicenseExists || !store.getters.isChatAtLeastAdvanced) ? ADVANCED_TAG : null
  },
  tags: AI_EXPERIMENTAL_TAG
}

export default condition
