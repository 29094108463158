
























import { ActionSetupView } from "@/components/TriggerSetup/logic/mixins/action-setup-view.mixin";
import AddAchievementTriggerAction from "./actions/AddAchievementTriggerAction";
import DeleteAchievementTriggerAction from "./actions/DeleteAchievementTriggerAction";
import { ActionsType } from "../../logic/types/types";

import { SelectOption } from "piramis-base-components/src/logic/types";

import { Component, Mixins } from 'vue-property-decorator'

@Component({
})
export default class AchievementsActionsWrapper extends Mixins<ActionSetupView>(ActionSetupView) {
  get viewBySendWarnMessage() {
    if (this.model.currentAction.type === ActionsType.AddAchievementTriggerAction) {
      return AddAchievementTriggerAction
    } else {
      return DeleteAchievementTriggerAction
    }
  }

  get achievementActionsOptions(): Array<SelectOption> {
    return [
      {
        label: this.$t(AddAchievementTriggerAction.titleKey),
        value: AddAchievementTriggerAction.structure.type
      },
      {
        label: this.$t(DeleteAchievementTriggerAction.titleKey),
        value: DeleteAchievementTriggerAction.structure.type
      }
    ]
  }

  onChange():void {
    // this.model.currentAction = this.viewBySendWarnMessage.structure
  }
}
