





































import { ActionSetupView } from "@/components/TriggerSetup/logic/mixins/action-setup-view.mixin";
import ActionChance from "@/components/TriggerSetup/components/ActionChance/ActionChance.vue";
import { mapTriggerOptions } from "@/includes/logic/Triggers";

import { Component, Mixins } from 'vue-property-decorator'
import TriggersMixin from "@/mixins/TriggersMixin";

@Component({
  'components': {
    ActionChance
  },
})
export default class ChangeTriggerActiveStateActionSetupView extends Mixins<ActionSetupView, TriggersMixin>(ActionSetupView, TriggersMixin) {
}
