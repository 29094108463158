import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { newBaseCondition } from "@/components/TriggerSetup/logic/structures/base-condition.structure";
import { ConditionsType, ConditionZone, TriggerCondition } from "@/components/TriggerSetup/logic/types/types";
import CheckBindedChannelSetupView from "./CheckBindedChannelSetupView.vue";
import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";
import store from "@/store/store";
import { BRAND_TAG } from "@/includes/constants";

const condition: EntityData = {
  'setupView': CheckBindedChannelSetupView,
  'structure': {
    'condition_type': ConditionsType.CheckBindedChannel,
    ...newBaseCondition,
  } as TriggerCondition,
  ...entityDataTitleKeyFields(ConditionsType.CheckBindedChannel, 'trigger_condition_'),
  extraProps: {
    possibleConditionZone: [ ConditionZone.Message, ConditionZone.ReplyMessage, ConditionZone.RequestActor, ConditionZone.ReactionActor,
      ConditionZone.ReactionMessage ]
  },
  limited: () => {
    if (!store.getters.isChatLicenseExists || !store.getters.getHaveBrand || !store.getters.getHaveLicense) {
      return BRAND_TAG
    } else {
      return null
    }
  }

}

export default condition
