import { render, staticRenderFns } from "./ForwardReplyMessageToTargetActionSetupView.vue?vue&type=template&id=39a2488a&scoped=true&"
import script from "./ForwardReplyMessageToTargetActionSetupView.vue?vue&type=script&lang=ts&"
export * from "./ForwardReplyMessageToTargetActionSetupView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39a2488a",
  null
  
)

export default component.exports