import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { ConditionsWrapperType } from "@/components/TriggerSetup/logic/types/types";
import FirstUserMessageTimeConditionsWrapper from "./FirstUserEnterTimeConditionsWrapper.vue";
import FirstUserEnterTimeMoreThat from "./conditions/FirstUserEnterTimeMoreThat";
import FirstUserEnterTimeLowerThat from "./conditions/FirstUserEnterTimeLowerThat";
import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";

const condition: EntityData = {
  'setupView': FirstUserMessageTimeConditionsWrapper,
  ...entityDataTitleKeyFields(ConditionsWrapperType.FirstUserEnterTimeConditionsWrapper, 'trigger_condition_'),
  structure: FirstUserEnterTimeLowerThat.structure,
  extraProps: {
    conditions: [
      FirstUserEnterTimeLowerThat,
      FirstUserEnterTimeMoreThat,
    ],
  }
}

export default condition
