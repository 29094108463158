














import { getConditionZoneIcon, getConditionZoneTitle } from "@/components/TriggerSetup/components/ConditionZone/helpers";
import { ConditionZone } from "@/components/TriggerSetup/logic/types/types";

import Icon from "piramis-base-components/src/components/Icon/Icon.vue";

import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  components: {
    Icon
  },
  methods: {
    getConditionZoneTitle,
    getConditionZoneIcon
  }
})
export default class ConditionZoneField extends Vue {
  @Prop() conditionZone!: ConditionZone

}
