var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"message-toxic-condition-setup-view"},[_c('select-input',{staticClass:"mb-0",attrs:{"setup":{
      'func': _vm.newConfigInputSetup,
      'args': {
        'model': this,
        'key': 'mode',
        'multiple': false,
        'options': _vm.toxicModeOptions,
        'hasAnchor': true,
        'clearable': false,
        'customHelpMessage': _vm.$t('field_mode_help_message_custom')
      },
    }}}),_c('select-input',{staticClass:"mb-0",attrs:{"setup":{
      'func': _vm.newConfigInputSetup,
      'args': {
        'model': this,
        'key': 'percent',
        'prefix': "ai_detect_",
        'validation': 'required',
        'options': _vm.percentOptions,
        'multiple': false,
        'clearable': false,
        disabled: _vm.disabled,
      }
    }}}),_c('condition-invert-settings',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.conditionData),callback:function ($$v) {_vm.conditionData=$$v},expression:"conditionData"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }