




























import { ConditionSetupView } from "@/components/TriggerSetup/logic/mixins/condition-setup-view.mixin";
import ConditionInvertSettings
  from '@/components/TriggerSetup/components/ConditionInvertSettings/ConditionInvertSettings.vue'
import SelectOptionsMixin from "@/mixins/SelectOptionsMixin";

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  'components': {
    ConditionInvertSettings
  }
})
export default class ReactionRemoveConditionSetupView extends Mixins(ConditionSetupView, SelectOptionsMixin) {
}
