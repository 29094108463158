import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import DeleteMessageActionSetupView from "@/components/TriggerSetup/actions/DeleteMessageAction/DeleteMessageActionSetupView.vue";
import { ActionsType } from "@/components/TriggerSetup/logic/types/types";
import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";

const action: EntityData = {
  'setupView': DeleteMessageActionSetupView,
  'structure': {
    'type': ActionsType.DeleteMessageAction,
    'target': "Caller",
    'delay' : 0,
    'chance': 100,
  },
  ...entityDataTitleKeyFields(ActionsType.DeleteMessageAction, 'trigger_action_'),
}

export default action
