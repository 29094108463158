







































import { ConditionSetupView } from "@/components/TriggerSetup/logic/mixins/condition-setup-view.mixin";
import AdvancedConditionSettings
  from "@/components/TriggerSetup/components/AdvancedConditionSettings/AdvancedConditionSettings.vue";
import ConditionInvertSettings
  from '@/components/TriggerSetup/components/ConditionInvertSettings/ConditionInvertSettings.vue'
import TariffsTagsHelper from "@/mixins/TariffsTagsHelper";
import { CheckBoostTargetEnum } from "@/components/TriggerSetup/conditions/CheckBoostWrapper/types";

import SelectInput from "piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue";
import NestedContent from "piramis-base-components/src/components/Pi/components/NestedContent.vue";
import { SelectOptionData } from "piramis-base-components/src/components/Pi/types";

import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'

@Component({
  data() {
    return {
      CheckBoostTargetEnum
    }
  },
  'components': {
    AdvancedConditionSettings,
    ConditionInvertSettings,
    SelectInput,
    NestedContent
  }
})
export default class CheckBoostSetupView extends Mixins<ConditionSetupView>(ConditionSetupView, TariffsTagsHelper) {
  @Prop({ type: Boolean }) disabled!: boolean

  type: CheckBoostTargetEnum = CheckBoostTargetEnum.Group

  get boostTarget() {
    return this.conditionData.value[0]
  }

  set boostTarget(value: string | null) {
    if (value === null) {
      this.conditionData.value.splice(0, 1)
    } else {
      this.conditionData.value.splice(0, 1, value)
    }
  }

  get boostType() {
    return this.type
  }

  set boostType(value: CheckBoostTargetEnum) {
    this.type = value
  }

  @Watch('boostType')
  onBoostTypeChange(value: CheckBoostTargetEnum) {
    if (value !== CheckBoostTargetEnum.FrontendCustom) {
      this.boostTarget = value
    } else {
      this.boostTarget = null
    }
  }

  get checkBoostConditionOptions(): Array<SelectOptionData> {
    return [
      {
        label: this.$t('check_boost_condition_target_group').toString(),
        value: CheckBoostTargetEnum.Group
      },
      {
        label: this.$t('check_boost_condition_target_linked_channel').toString(),
        value: CheckBoostTargetEnum.LinkedChannel,
        tags: this.checkLicense ? this.advancedLicenseTag : null,
        disabled: this.checkLicense ? !!this.advancedLicenseTag : false
      },
      {
        label: this.$t('check_boost_condition_target_frontend_custom').toString(),
        value: CheckBoostTargetEnum.FrontendCustom,
        tags: this.checkLicense ? this.brandTag : null,
        disabled: this.checkLicense ? !!this.brandTag : false
      },
    ]
  }

  mounted() {
    if ([ CheckBoostTargetEnum.Group, CheckBoostTargetEnum.LinkedChannel ].includes(this.conditionData.value[0])) {
      this.boostType = this.conditionData.value[0]
    }
  }
}
