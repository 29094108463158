import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { newBaseCondition } from "@/components/TriggerSetup/logic/structures/base-condition.structure";
import {
  ConditionsType,
  ConditionZone,
  TriggerCondition
} from "@/components/TriggerSetup/logic/types/types";
import FirstUserMessageTimeMoreThatSetupView
  from "@/components/TriggerSetup/conditions/FirstUserMessageTimeConditionsWrapper/conditions/FirstUserMessageTimeMoreThat/FirstUserMessageTimeMoreThatSetupView.vue";
import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";

const condition: EntityData = {
  'setupView': FirstUserMessageTimeMoreThatSetupView,
  'structure': {
    'condition_type': ConditionsType.FirstUserMessageTimeMoreThat,
    ...newBaseCondition,
  } as TriggerCondition,
  ...entityDataTitleKeyFields(ConditionsType.FirstUserMessageTimeMoreThat, 'trigger_condition_'),
  extraProps: {
    possibleConditionZone: [ ConditionZone.Message, ConditionZone.ReplyMessage, ConditionZone.RequestActor, ConditionZone.ReactionActor,
      ConditionZone.ReactionMessage ]
  }

}

export default condition
