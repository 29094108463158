





















import { ConditionSetupView } from "@/components/TriggerSetup/logic/mixins/condition-setup-view.mixin";
import ConditionInvertSettings
  from '@/components/TriggerSetup/components/ConditionInvertSettings/ConditionInvertSettings.vue'

import { Component, Mixins } from 'vue-property-decorator'
import { round } from "lodash";

@Component({
  'components': {
    ConditionInvertSettings
  }
})
export default class FirstUserEnterTimeMoreThatSetupView extends Mixins<ConditionSetupView>(ConditionSetupView) {
  get numberValue(): number {
    return this.conditionData.value[0] || 0
  }

  set numberValue(value: number) {
    this.$set(this.conditionData, 'value', [ Math.round(value).toString() ])
  }
}
