import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { newBaseCondition } from "@/components/TriggerSetup/logic/structures/base-condition.structure";
import { ConditionsType, ConditionZone, TriggerCondition } from "@/components/TriggerSetup/logic/types/types";
import ThreadIdSetupView from "./ThreadIdSetupView.vue";
import store from "@/store/store";
import { FORUM_TAG, STARTER_TAG } from '@/includes/constants'
import { TagData } from "piramis-base-components/src/components/Tags/types";
import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";

const conditionLimited = (): null | Array<TagData> => {
  if (store.state.chatState.chat) {
    const tags: Array<TagData> = []

    if ((store.state.chatState.chat as any).type !== 'forum') {
      tags.push(...FORUM_TAG)
    }

    if (!store.getters.isChatLicenseExists) {
      tags.push(...STARTER_TAG)
    }

    return tags.length ? tags : null
  }

  return null
}

const condition: EntityData = {
  'setupView': ThreadIdSetupView,
  'structure': {
    'condition_type': ConditionsType.ThreadId,
    ...newBaseCondition,
  } as TriggerCondition,
  ...entityDataTitleKeyFields(ConditionsType.ThreadId, 'trigger_condition_'),
  'limited': conditionLimited,
  extraProps: {
    possibleConditionZone: [ ConditionZone.Message, ConditionZone.ReplyMessage, ConditionZone.RequestMessage, ConditionZone.ReactionMessage ]
  }
}

export default condition
