var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mute-action"},[_c('select-input',{attrs:{"setup":{
      'func': _vm.triggerInputSetupFn,
      'args': {
        model: _vm.model,
        'key': 'achievement',
        'prefix': 'trigger_action_',
        'options': _vm.$store.getters.achievementOptions,
        'clearable': false,
        'multiple': false,
        'validation': 'required',
        disabled: _vm.disabled,
      }
    }}}),_c('action-targets',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }