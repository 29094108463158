var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"trigger-action-bind-target"},[_c('a-modal',{attrs:{"title":_vm.$t('pi_base_components_editor_topics'),"footer":null},model:{value:(_vm.isBindTopicModalOpen),callback:function ($$v) {_vm.isBindTopicModalOpen=$$v},expression:"isBindTopicModalOpen"}},[_c('bind-topic-input',{staticClass:"mb-0",on:{"pick-topic-handler":function($event){_vm.threadId = $event; _vm.isBindTopicModalOpen = false}}})],1),_c('a-spin',{staticClass:"w-full mt-5",attrs:{"spinning":_vm.isChecking}},[(_vm.target)?[(_vm.bindTarget.info)?[_c('target-card',{attrs:{"target":_vm.bindTarget.info}}),(_vm.hasThreadBind)?_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.bindTarget.info.type === 'FORUM')?_c('nested-content',{staticClass:"flex items-center justify-between"},[(_vm.threadId)?[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('editor_custom_topic_selected_value', [_vm.threadId]))}})]:[_vm._v(" "+_vm._s(_vm.$t('topic_not_binded'))+" ")],(!_vm.threadId)?_c('a-button',{on:{"click":function($event){_vm.isBindTopicModalOpen = true}}},[_vm._v(" "+_vm._s(_vm.$t('bind'))+" ")]):_c('div',{staticClass:"flex gap-1"},[_c('a',{staticClass:"text-warning p-1 hover:opacity-75",attrs:{"title":_vm.$t('edit')},on:{"click":function($event){$event.preventDefault();_vm.isBindTopicModalOpen = true}}},[_c('a-icon',{attrs:{"type":"edit"}})],1),_c('a',{staticClass:"text-danger p-1  hover:opacity-75",attrs:{"title":_vm.$t('remove')},on:{"click":function($event){$event.preventDefault();_vm.threadId = ''}}},[_c('a-icon',{attrs:{"type":"delete"}})],1)])],2):_vm._e()],1):_vm._e()]:_vm._e()]:_c('b',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.$t('target_not_binded'))+" ")])],2),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('text-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.bindTarget,
          'key': 'id',
          'prefix': 'trigger_action_',
          'validation': 'integer',
          disabled: _vm.disabled,
        }
      }},on:{"pressEnter":function($event){!invalid ? _vm.checkBindChannel(_vm.bindTarget.id) : false}}},[_c('a-button',{attrs:{"slot":"input-button","type":"primary","disabled":invalid},on:{"click":function($event){!invalid ? _vm.checkBindChannel(_vm.bindTarget.id) : false}},slot:"input-button"},[_vm._v(" "+_vm._s(_vm.$t('bind'))+" ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }