var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"action-users"},[(_vm.$store.getters.isChatAtLeastAdvanced && !_vm.$store.getters.getHaveLicense || !_vm.$store.getters.getHaveBrand)?_c('select-input',{attrs:{"setup":{
      'func': _vm.triggerInputSetupFn,
      'args': {
        model: _vm.model,
        'key': 'users',
        'prefix': 'trigger_action_admin_',
        disabled: _vm.disabled,
        'validation': 'required',
        'options': _vm.receivedAdministratorsMapSelectOptions,
        'multiple': true
      }
    }}}):_vm._e(),_c('a-spin',{attrs:{"spinning":_vm.usersInfoService.isLoading}},[(_vm.$store.getters.getHaveLicense && _vm.$store.getters.getHaveBrand)?_c('chips-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.model,
          'key': 'users',
          'prefix': 'trigger_action_',
          disabled: _vm.disabled,
          'validation': 'required',
          'options': _vm.receivedAdministratorsMapValues,
          'formatter': _vm.format
        }
      }}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }