import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { baseCondition } from "@/components/TriggerSetup/logic/structures/base-condition.structure";
import NumberConditionSetupView
  from "@/components/TriggerSetup/components/NumberConditionSetupView/NumberConditionSetupView.vue";
import { ConditionsType, TriggerCondition } from "@/components/TriggerSetup/logic/types/types";
import { snakeCase } from "lodash";
import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";

const condition: EntityData = {
  'setupView': NumberConditionSetupView,
  'structure': {
    'type': ConditionsType.ReplyXpEquals,
    ...baseCondition,
    ...{ 'value': [ 0 ] }
  } as TriggerCondition,
  ...entityDataTitleKeyFields(ConditionsType.ReplyXpEquals, 'trigger_condition_'),
}

export default condition
