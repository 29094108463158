














import ActionChance from "@/components/TriggerSetup/components/ActionChance/ActionChance.vue";
import ActionText from "@/components/TriggerSetup/components/ActionText/ActionText.vue";
import { ActionSetupView } from "@/components/TriggerSetup/logic/mixins/action-setup-view.mixin";
import Placeholders from "@/mixins/placeholders/placeholders";

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  'components': {
    ActionChance,
    ActionText
  }
})
export default class SendFormToChatSetupView extends Mixins<ActionSetupView, Placeholders>(ActionSetupView, Placeholders) {

}
