var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"add-message-reaction-action-setup-view"},[_c('select-input',{attrs:{"setup":{
      'func': _vm.conditionInputSetupFn,
      'args': {
        'model': _vm.model,
        'key': 'reply',
        'options': _vm.replyOptions,
        'prefix': 'add_message_reaction_action_',
        'clearable': false,
      }
    }}}),_c('select-input',{attrs:{"setup":{
      'func': _vm.conditionInputSetupFn,
      'args': {
        'model': _vm.model,
        'key': 'reaction',
        'options': _vm.emojiOptions,
        'prefix': 'add_message_reaction_action_',
        'clearable': false,
      }
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }