






























import { ActionSetupView } from "@/components/TriggerSetup/logic/mixins/action-setup-view.mixin";
import SelectOptionsMixin from "@/mixins/SelectOptionsMixin";

import { Component, Mixins } from 'vue-property-decorator';
import { SelectOption } from "piramis-base-components/src/logic/types";

@Component({
})
export default class AddMessageReactionActionSetupView extends Mixins(ActionSetupView, SelectOptionsMixin) {
  get replyOptions(): Array<SelectOption> {
    return [
      {
        label: this.$t('add_message_reaction_action_reply_false').toString(),
        value: false
      },
      {
        label: this.$t('add_message_reaction_action_reply_true').toString(),
        value: true
      },
    ]
  }
}
