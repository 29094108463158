import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { baseCondition } from "@/components/TriggerSetup/logic/structures/base-condition.structure";
import { ConditionsType, TriggerCondition } from "@/components/TriggerSetup/logic/types/types";
import FirstUserMessageTimeLowerThatSetupView
  from "@/components/TriggerSetup/conditions/FirstUserMessageTimeConditionsWrapper/conditions/FirstUserMessageTimeLowerThat/FirstUserMessageTimeLowerThatSetupView.vue";
import { snakeCase } from "lodash";
import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";

const condition: EntityData = {
  'setupView': FirstUserMessageTimeLowerThatSetupView,
  'structure': {
    'type': ConditionsType.FirstUserMessageTimeLowerThat,
    ...baseCondition,
  } as TriggerCondition,
  ...entityDataTitleKeyFields(ConditionsType.FirstUserMessageTimeLowerThat, 'trigger_condition_')
}

export default condition
