import { MessageVariants } from "@/views/posts-planner/posts.types";
import { ActionsType, ActionsWrapperType } from "@/components/TriggerSetup/logic/types/types";

import { Component } from "vue-property-decorator";
import Vue from "vue";

@Component
export default class TriggerValidationMixin extends Vue {
  firstInvalidTriggerField(validationMap: Record<string, boolean>):string | null  {
    return Object.keys(validationMap).filter(field => !validationMap[field])[0] ?? null
  }

  isTriggerValid(validationMap: Record<string, boolean>)  {
    return Object.keys(validationMap).every(field => validationMap[field])
  }

  hasActionGroups(actionModel:Array<any>):boolean {
    return actionModel.length !== 0 && actionModel.every((item: any) => item.value.actions.length !== 0)
  }

  checkTextAction(list: Array<MessageVariants>):boolean {
    return list.every(editor => editor.text.trim().length !== 0 || (editor.attachments && editor.attachments.length > 0))
  }

  actionValuesValid(actionModel:Array<any>, callback?:(value:any) => boolean):boolean {
    if (this.hasActionGroups(actionModel)) {
      return actionModel.every(g => g.value.actions.every((a:any) => callback !== undefined ? callback(a.value.structure) : this.checkAction(a.value.structure)))
    }

    return false
  }

  checkAction(action: any): boolean {
    const infusionValidActions: Array<ActionsType | ActionsWrapperType> = [
      ActionsType.KickAction,
      ActionsType.DeleteMessageAction,
      ActionsType.BanAction,
      ActionsType.UnbanAction,
      ActionsType.MuteAction,
      ActionsType.UnmuteAction,
      ActionsType.ChangeReputationIntervalAction,
      ActionsType.SetReputationAction,
      ActionsType.ChangePointsIntervalAction,
      ActionsType.SetPointsAction,
      ActionsType.ChangeXpIntervalAction,
      ActionsWrapperType.WarnActionsWrapper,
      ActionsType.TriggerWarnAction,
      ActionsType.PinMessageAction,
      ActionsType.UnPinMessageAction,
      ActionsType.AddMessageReactionAction,
      ActionsType.CompleteCaptchaQuestAction
    ]

    const actionsWithMessage = [
      ActionsType.SendMessageAction,
      ActionsType.SendPrivateMessageAction,
      ActionsType.SendFormToChat,
      ActionsType.SendFormToPrivate,
      ActionsType.SendFormResultToTarget,
      ActionsType.SendMessageToTargetAction
    ]

    const actionsWithTargets = [
      ActionsType.SendMessageToTargetAction,
      ActionsType.ForwardReplyMessageToTargetAction,
      ActionsType.ForwardMessageToTargetAction,
    ]

    const actionsWithInterval = [ ActionsType.ChangePointsIntervalAction, ActionsType.ChangeReputationIntervalAction, ActionsType.ChangeXpIntervalAction ]

    let isInvalid = false

    if (actionsWithMessage.includes(action.type)) {
      isInvalid = this.checkTextAction(action.text)
    }

    if (actionsWithTargets.includes(action.type)) {
      isInvalid = action.target !== null && action.target !== ''
    }

    if (action.type === ActionsType.SendMessageToCalculatedTargetAction) {
      isInvalid = this.checkTextAction(action.text) && this.checkTextAction(action.failedText)
    }

    if (action.type === ActionsType.ChangeTriggerActiveStateAction) {
      isInvalid = action.triggerKey !== null
    }

    if (action.type === ActionsWrapperType.AchievementsActionsWrapper) {
      isInvalid = !!action.currentAction.achievement
    }

    if (actionsWithInterval.includes(action.type)) {
      if ([ action.from, action.to ].every(field => !isNaN(field))) {
        isInvalid = true
      }
    }

    if (infusionValidActions.includes(action.type)) {
      isInvalid = true
    }

    return isInvalid
  }
}
