











import TriggerActionBindTarget from "../../components/TriggerActionBindTarget.vue";
import { ActionSetupView } from "@/components/TriggerSetup/logic/mixins/action-setup-view.mixin";

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  components: {
    TriggerActionBindTarget,
  }
})
export default class ForwardMessageToTargetActionSetupView extends Mixins<ActionSetupView>(ActionSetupView) {

}
