import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { ActionsWrapperType } from "@/components/TriggerSetup/logic/types/types";

import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";
import AddAchievementTriggerActionSetupView from "./actions/AddAchievementTriggerAction";
import AchievementsActionsWrapper from "./AchievementsActionsWrapper.vue";
import store from "@/store/store";
import { ULTIMATE_TAG } from "@/includes/constants";

const action: EntityData = {
  'setupView': AchievementsActionsWrapper,
  'structure': {
    'type': ActionsWrapperType.AchievementsActionsWrapper,
    'currentAction': AddAchievementTriggerActionSetupView.structure
  },
  limited: () => store.getters.getHaveLicense && store.getters.isChatLicenseExists ? null : ULTIMATE_TAG,
  ...entityDataTitleKeyFields(ActionsWrapperType.AchievementsActionsWrapper, 'trigger_action_'),
}

export default action
