import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";

import { ActionsType } from "@/components/TriggerSetup/logic/types/types";
import { entityDataTitleKeyFields } from "../../../../logic/helpers";
import AddAchievementTriggerActionSetupView from "./AddAchievementTriggerActionSetupView.vue";

const action: EntityData = {
  'setupView': AddAchievementTriggerActionSetupView,
  'structure': {
    'type': ActionsType.AddAchievementTriggerAction,
    'target'      : "Caller",
    'achievement' : null,
    'chance'      : 100,
  },
  ...entityDataTitleKeyFields(ActionsType.AddAchievementTriggerAction, 'trigger_action_'),
}

export default action
