



















import { ActionSetupView } from "@/components/TriggerSetup/logic/mixins/action-setup-view.mixin";

import { Component, Mixins } from 'vue-property-decorator'

@Component
export default class ActionChance extends Mixins<ActionSetupView>(ActionSetupView) {

}
