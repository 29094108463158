import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { newBaseCondition } from "@/components/TriggerSetup/logic/structures/base-condition.structure";
import { ConditionsType, ConditionZone, TriggerCondition } from "@/components/TriggerSetup/logic/types/types";
import ConditionRank from '@/components/TriggerSetup/components/ConditionRank/ConditionRank.vue'
import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";

const condition: EntityData = {
  'setupView': ConditionRank,
  'structure': {
    'condition_type': ConditionsType.RankUpper,
    ...newBaseCondition,
    ...{ 'value': [ 0 ] }
  } as TriggerCondition,
  ...entityDataTitleKeyFields(ConditionsType.RankUpper, 'trigger_condition_'),
  extraProps:{
    possibleConditionZone: [ ConditionZone.Message, ConditionZone.ReplyMessage, ConditionZone.RequestActor, ConditionZone.ReactionActor,
      ConditionZone.ReactionMessage ]
  }

}

export default condition
