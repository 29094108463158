import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import ForwardMessageToTargetActionSetupView
  from "@/components/TriggerSetup/actions/ForwardMessageToTargetAction/ForwardMessageToTargetActionSetupView.vue";
import { ActionsType } from "@/components/TriggerSetup/logic/types/types";
import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";
import store from "@/store/store";
import { BRAND_TAG } from "@/includes/constants";

const action: EntityData = {
  'setupView': ForwardMessageToTargetActionSetupView,
  'structure': {
    'type': ActionsType.ForwardMessageToTargetAction,
    'target': null,
    'chance': 100,
    'threadId': '',
  },
  ...entityDataTitleKeyFields(ActionsType.ForwardMessageToTargetAction, 'trigger_action_'),
  limited: () => store.getters.getHaveLicense && store.getters.getHaveBrand && store.getters.isChatLicenseExists ? null : BRAND_TAG
}

export default action
