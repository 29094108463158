





















import { ConditionSetupView } from "@/components/TriggerSetup/logic/mixins/condition-setup-view.mixin";
import ConditionInvertSettings
  from '@/components/TriggerSetup/components/ConditionInvertSettings/ConditionInvertSettings.vue'
import SelectOptionsMixin from "@/mixins/SelectOptionsMixin";

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  'components': {
    ConditionInvertSettings
  }
})
export default class MessageLangConditionSetupView extends Mixins(ConditionSetupView, SelectOptionsMixin) {
  get languages() {
    return this.conditionData.value.slice(1)
  }

  set languages(value: Array<string>) {
    this.conditionData.value.splice(1, this.conditionData.value.length, ...value)
  }
}
