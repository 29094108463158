






































import { ActionSetupView } from '@/components/TriggerSetup/logic/mixins/action-setup-view.mixin'
import ActionInterval from '@/components/TriggerSetup/components/ActionInterval/ActionInterval.vue'

import Icon from 'piramis-base-components/src/components/Icon/Icon.vue'
import NestedContent from 'piramis-base-components/src/components/Pi/components/NestedContent.vue'

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  'components': {
    ActionInterval,
    Icon,
    NestedContent
  }
})
export default class ChangePointsIntervalActionSetupView extends Mixins<ActionSetupView>(ActionSetupView) {

}
