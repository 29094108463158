






















import { ConditionSetupView } from '../../../logic/mixins/condition-setup-view.mixin'

import { SelectOption } from 'piramis-base-components/src/logic/types'

import { Component, Emit, Mixins, Prop } from 'vue-property-decorator'
import { cloneDeep, snakeCase } from 'lodash'
import { ConditionsType } from "@/components/TriggerSetup/logic/types/types";
import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import ConditionWrapperMixin from "@/components/TriggerSetup/conditions/new/ConditionWrapperMixin";

@Component
export default class MessageTextLenConditionsWrapper extends Mixins(ConditionSetupView, ConditionWrapperMixin) {
}
