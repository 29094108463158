
























import { ActionSetupView } from "@/components/TriggerSetup/logic/mixins/action-setup-view.mixin";
import ActionChance from "@/components/TriggerSetup/components/ActionChance/ActionChance.vue";
import ActionText from "@/components/TriggerSetup/components/ActionText/ActionText.vue";
import ActionUsers from "@/components/TriggerSetup/components/ActionUsers/ActionUsers.vue";
import ActionFormButtonAction
  from '@/components/TriggerSetup/components/ActionFormButtonAction/ActionFormButtonAction.vue'
import Placeholders from "@/mixins/placeholders/placeholders";

import { Component, Mixins, Prop } from 'vue-property-decorator'

@Component({
  'components': {
    ActionChance,
    ActionText,
    ActionUsers,
    ActionFormButtonAction
  }
})
export default class SendPrivateMessageActionSetupView extends Mixins<ActionSetupView, Placeholders>(ActionSetupView, Placeholders) {
  @Prop({ 'type': Boolean, 'required': false, 'default': false }) hasForm!:boolean

}
