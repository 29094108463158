var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"check-binded-channel-setup-view"},[_c('chips-input',{attrs:{"setup":{
      'func': _vm.conditionInputSetupFn,
      'args': {
        'model': this,
        'key': 'channels',
        'prefix': 'check_binded_channel_',
        'disabled': _vm.disabled,
      }
    }}}),_c('condition-invert-settings',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.conditionData),callback:function ($$v) {_vm.conditionData=$$v},expression:"conditionData"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }