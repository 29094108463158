import { ConditionsType } from "@/components/TriggerSetup/logic/types/types";
import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";

import { SelectOption } from "piramis-base-components/src/logic/types";

import Vue from "vue";
import { cloneDeep, snakeCase } from "lodash";
import { Component, Emit, Prop } from "vue-property-decorator";

@Component
export default class ConditionWrapperMixin extends Vue {
  @Prop() conditions!: Array<EntityData>

  @Prop() conditionType!: ConditionsType

  @Emit()
  updateCondition(value: EntityData | undefined) {
    return value
  }

  get type() {
    return this.conditionType
  }

  set type(value: ConditionsType) {
    this.updateCondition(cloneDeep(this.conditions.find(c => c.structure.condition_type === value)))
  }

  get conditionOptions():Array<SelectOption> {
    return this.conditions.map(condition => ({
      label: this.$t(`trigger_condition_${ snakeCase(condition.structure.condition_type) }`),
      value: condition.structure.condition_type
    }))
  }
}
