import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import KickActionSetupView from "@/components/TriggerSetup/actions/KickAction/KickActionSetupView.vue";
import { ActionsType } from "@/components/TriggerSetup/logic/types/types";
import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";

const action: EntityData = {
  'setupView': KickActionSetupView,
  'structure': {
    'type': ActionsType.KickAction,
    'target': "Caller",
    'chance': 100,
    'targetSource': ''
  },
  ...entityDataTitleKeyFields(ActionsType.KickAction, 'trigger_action_'),
}

export default action
