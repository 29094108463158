
















































































































































































import { TriggerData } from '@/components/TriggerSetup/logic/types/types'
import { TriggerHelper, TriggerInputsSetups } from '@/components/TriggerSetup/logic/mixins/TriggerSetupMixin'
import ActionsGroups from '@/components/TriggerSetup/components/ActionsGroups/ActionsGroups.vue'
import { EntityData } from '@/components/TriggerSetup/logic/types/entity-data.type'
import CenteredColumnLayout from '@/components/CenteredColumnLayout.vue'
import { InputSetups } from '@/mixins/input-setups'
import TriggerAdvancedValidateMixin
  from '@/components/TriggerSetup/components/TriggerAdvancedValidate/TriggerAdvancedValidateMixin.vue'
import { TriggersMode } from "@/includes/types/triggers/Enums";

import Overlay from 'piramis-base-components/src/components/Overlay/Overlay.vue'
import { UseFields } from 'piramis-base-components/src/components/Pi/index'
import HelpMessage from "piramis-base-components/src/components/HelpMessage/HelpMessage.vue";

import { Component, Mixins, Prop, VModel } from 'vue-property-decorator'
import { ValidationObserver } from 'vee-validate'
import { cloneDeep } from 'lodash'

@Component({
  data() {
    return {
      TriggersMode
    }
  },
  'components': {
    ValidationObserver,
    ActionsGroups,
    Overlay,
    CenteredColumnLayout,
    TriggerAdvancedValidateMixin,
    HelpMessage
  }
})
export default class TriggerAdvancedConditionSettings extends Mixins<UseFields, InputSetups, TriggerHelper, TriggerInputsSetups>(UseFields, InputSetups, TriggerHelper, TriggerInputsSetups) {
  @VModel({ 'type': Object, 'required': true }) model!: TriggerData

  @Prop({ 'type': Boolean, 'default': false }) readonly disabled!: boolean

  @Prop({ 'type': Array, 'required': true }) filteredActions!: Array<Array<EntityData>>

  @Prop({ 'type': Boolean, 'required': false, 'default': false }) hasForm!: boolean

  modelCopy: TriggerData | null = null

  isPopupOpen: boolean = false

  isDisableNotifyOpen = false

  get conditionFailActionsGroups(): any {
    return this.modelCopy ? this.modelCopy.conditionFailActions : []
  }

  set conditionFailActionsGroups(value: any) {
    (this.modelCopy as TriggerData).conditionFailActions = value
  }

  get conditionSuccessActionsGroups(): any {
    return this.modelCopy ? this.modelCopy.conditionSuccessActions : []
  }

  set conditionSuccessActionsGroups(value: any) {
    (this.modelCopy as TriggerData).conditionSuccessActions = value
  }

  get isModified(): boolean {
    return Boolean(
      this.model.conditionFailActions.length ||
        this.model.conditionSuccessActions.length
    )
  }

  openPopup(): void {
    this.modelCopy = cloneDeep(this.model)
    this.isPopupOpen = true
  }

  saveChanges(): void {
    this.model = this.modelCopy as TriggerData
    this.isPopupOpen = false
  }
}
