import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { baseCondition } from "@/components/TriggerSetup/logic/structures/base-condition.structure";
import { ConditionsType, TriggerCondition } from "@/components/TriggerSetup/logic/types/types";
import WorkDatesSetupView from "@/components/TriggerSetup/conditions/WorkDates/WorkDatesSetupView.vue";
import { snakeCase } from "lodash";
import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";

const condition: EntityData = {
  'setupView': WorkDatesSetupView,
  'structure': {
    'type': ConditionsType.WorkDates,
    ...baseCondition,
  } as TriggerCondition,
  ...entityDataTitleKeyFields(ConditionsType.WorkDates, 'trigger_condition_'),
}

export default condition
