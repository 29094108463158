import { EntityData } from "@/components/TriggerSetup/logic/types/entity-data.type";
import { ConditionsWrapperType } from "@/components/TriggerSetup/logic/types/types";
import ActionPointsConditionsWrapper from "./ActionPointsConditionsWrapper.vue";
import ActionPointsEquals from "./conditions/ActionPointsEquals";

import store from "@/store/store";
import ActionPointsLower from "./conditions/ActionPointsLower";
import ActionPointsUpper from "./conditions/ActionPointsUpper";
import { ADVANCED_TAG } from "@/includes/constants";
import { entityDataTitleKeyFields } from "@/components/TriggerSetup/logic/helpers";

const condition: EntityData = {
  'setupView': ActionPointsConditionsWrapper,
  ...entityDataTitleKeyFields(ConditionsWrapperType.ActionPointsConditionsWrapper, 'trigger_condition_'),
  structure: ActionPointsEquals.structure,
  extraProps: {
    conditions: [
      ActionPointsEquals,
      ActionPointsLower,
      ActionPointsUpper,
    ],
  },
  limited: () => {
    if (store.getters.isChatSet && store.getters.isChatLicenseExists && store.getters.isChatAtLeastAdvanced) {
      return null
    } else {
      return ADVANCED_TAG
    }
  }
}

export default condition
